import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import csv from "csv";
import Dropzone from 'react-dropzone';
import { Col, Row } from "reactstrap";
import DragNDropIcon from '../../assets/images/icons/upload-image-icon.png'
import { useStoreActions, useStoreState } from 'easy-peasy';



function CSVDropZone(props) {

    const setDropData = useStoreActions(actions => actions.CSVDrop.setDropData);

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading failed");
        reader.onload = () => {
            // Parse CSV file
            csv.parse(reader.result, (err, data) => {
                setDropData(data);
            });
        };

        // read file contents
        acceptedFiles.forEach(file => reader.readAsText(file));
    }, []);

    const files = props.files.map(file => (
        <li key={file.name}>
            {file.name}
        </li>
    ));

    const renderAcceptedFiles = () => {
        if (files.length !== 0) {
            return (
                <div>
                    <h4>Accepted Files</h4>
                    <ul>{files}</ul>
                </div>
            )
        }
    }

    return (
        <Dropzone onDrop={onDrop} accept={props.fileTypes}>
            {({ getRootProps, getInputProps }) => (
                <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <Row className="justify-content-md-center" style={{ marginTop: "25px" }}>
                            <h6>{props.header}</h6>
                        </Row>
                        <Row className="justify-content-md-center">
                            <div>{props.subheader}</div>
                        </Row>
                        <Row className="justify-content-md-center" style={{ marginTop: "25px" }}>
                            <img src={DragNDropIcon} width='50px' height='50px' alt="DragNDrop" />
                        </Row>
                    </div>
                    <aside>
                        {renderAcceptedFiles()}
                    </aside>
                </section>
            )}
        </Dropzone>
    );

}

export default CSVDropZone;