import React from "react";
import { TextField, MenuItem } from '@material-ui/core';
import '../../styles/ProductCreateForm.css';
import FieldError from "./Product/FieldError";
import { Controller } from 'react-hook-form';

const FormControllerSelectField = (props) => {

  /* 
    name: can 
   
    options: a list of objects with two string properties: value & label
   */
  const { name, defaultValue, control, errorMessage, options} = props;

  return (
    <div >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => (<>
          <TextField
            select
            label={name}
            variant="outlined"
            fullWidth
            size="small"
            error={!!error}
            {...field}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }
            }}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {!!error && <FieldError errorMessage={errorMessage} />}
        </>
        )}
      />
    </div>
  )
};

export default FormControllerSelectField;