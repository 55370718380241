import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { List } from "react-virtualized";
import '../../styles/ProductCreateForm.css';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = value =>
  makeStyles(theme => ({
    root: {
      "& .Mui-error": {
        color: "#DC4C64"
      }
    }
  }));

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={450}
          width={450}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(props) => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});


export default function VirtualizedList(props) {
  const { options, name, setFilterOption } = props;
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = React.useState("");

  const handleInputChange = (event, newValue) => {
    setSelectedItem(newValue);
    const selectedOption = options.find(option => option.Name === newValue);
    const filterOption = selectedOption ? selectedOption.Id : null;
    setFilterOption(filterOption);
  };

  return <>
    <div>
      <Autocomplete
        id="virtualize-demo"
        disableListWrap
        ListboxComponent={ListboxComponent}
        inputValue={selectedItem}
        onInputChange={handleInputChange}
        options={options}
        getOptionLabel={(option) => option.Name}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              className={classes.root}
              variant="outlined"
              size="small"
              label={name}
              fullWidth
            />
          </>
        )}
      />
    </div>
  </>
}