import React, { useEffect, useState } from "react";
import '../../../styles/ProductCreateForm.css';
import { useForm, useFormContext, Controller } from 'react-hook-form';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ProductFormTextField from "./ProductFormField";
import ProductFormSelectField from "./ProductFormSelectField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@mui/material/Grid';
import { ibcEnterpriseApi } from '../../../apis/ibcEnterpriseApi';
import { FixedSizeList } from 'react-window';
import VirtualizedAutocomplete from '../VirtualizedAutocomplete'
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import FormControllerSelectField from '../FormControllerSelectField';

// Define your validation schema
const validationSchema = yup.object().shape({
  Brand: yup.object().shape({
    Name: yup.string().required()
  }),
  Category: yup.object().shape({
    Name: yup.string().required()
  }),
  ProductFamily: yup.object().shape({
    Name: yup.string().required()
  }),
  Subcategory: yup.object().shape({
    Name: yup.string().required()
  }),
  Item: yup.string().required(),
  IsColdVault: yup.string().required(),
  IsAlcohol: yup.string().required(),
  ProductType: yup.string().required(),
  VerificationStatus: yup.string().required(),
  UPCA: yup.string().min(10, 'Phone number must be at least 10 digits')
    .max(12, 'Phone number cannot be more than 12 digits')
    .matches(/^[0-9]+$/, 'String must be an integer')
    .required(),
  MaterialNumber: yup.string().test(val => (isNumber(val))).nullable(true),
  CaseQuantity: yup.string().test(val => (isNumber(val))).nullable(true),
  Height: yup.string().test(val => (isNumber(val))).nullable(true),
  Width: yup.string().test(val => (isNumber(val))).nullable(true),
  Depth: yup.string().test(val => (isNumber(val))).nullable(true),
  Weight: yup.string().test(val => (isNumber(val))).nullable(true)
});

const trueFalseOptions = [{ value: true, label: "True" }, { value: false, label: "False" }]
const productTypeOptions = [{ value: "Immediate", label: "Immediate" }, { value: "Future", label: "Future" }]
const verificationStatusOptions = [{ value: "Unverified", label: "Unverified" }, { value: "Verified", label: "Verified" }, { value: "Could Not Verify", label: "Could Not Verify" }]

const isNumber = (value) => {
  // if it's a string, it can be a number'
  if (typeof value === 'string' || value instanceof String) {
    if (value === "") return true;
    if (/\s/.test(value)) return false;
  }
  return !value || !isNaN(value);
}

function ProductForm(props) {
  const { product, brands, categories, buttonLabel, dataAutofilled = true } = props;

  // Getting state references w/ 'userStoreState'
  const {
    ibcProducts,
  } = useStoreState(state => state);
  const [productFamilies, setProductFamilies] = useState([]);
  const [productSubCategories, setProductSubCategories] = useState([]);


  // Setting and Changing state w/ 'userStoreActions'
  const {
    setProduct,
  } = useStoreActions(actions => actions);

  const onSubmit = (data) => {
    product.ShortDescription = data.Item;
    product.UPCA = data.UPCA;
    product.ProductBrand = data.Brand;
    product.ProductBrandId = data.Brand.Id;
    product.ProductCategory = data.Category;
    product.ProductCategoryId = data.Category.Id;
    product.ProductSubCategory = data.Subcategory;
    product.ProductSubCategoryId = data.Subcategory.Id;
    product.ProductFamily = data.ProductFamily;
    product.ProductFamilyId = data.ProductFamily.Id;
    product.MaterialNumber = data?.MaterialNumber;
    product.CaseQuantity = data?.CaseQuantity;
    product.PackType = data?.PackType;
    product.PackSize = data?.PackSize;
    product.HeightInches = data?.Height;
    product.WidthInches = data?.Width;
    product.DepthInches = data?.Depth;
    product.WeightPounds = data?.Weight;
    product.VendorProductName = data?.VendorsProductName;
    product.FullDescription = data?.Description;
    product.IbcProduct = data?.IBCProduct ? data.IBCProduct : null;
    product.IbcProductId = data?.IBCProduct?.Id ? data.IBCProduct.Id : null;
    product.IsColdVault = data?.IsColdVault;
    product.IsAlcohol = data?.IsAlcohol;
    product.ProductType = data?.ProductType;
    product.VerificationStatus = data?.VerificationStatus;
    setProduct(product);
    props.onSave();
  }

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });

  useEffect(() => {

    if (product?.UPCA && dataAutofilled) {
      reset({
        Item: product.ShortDescription,
        UPCA: product.UPCA,
        Brand: brands.find(brand => brand.Id === product.ProductBrandId),
        Category: categories.find(category => category.Id === product.ProductCategoryId),
        Subcategory: productSubCategories.find(subCategory => subCategory.Id === product.ProductSubCategoryId),
        ProductFamily: productFamilies.find(productFamily => productFamily.Id === product.ProductFamilyId),
        IBCProduct: ibcProducts.find(ibcProduct => ibcProduct.Id === product.IbcProductId),
        MaterialNumber: product.MaterialNumber,
        CaseQuantity: product.CaseQuantity,
        PackType: product.PackType,
        PackSize: product.PackSize,
        Height: product.HeightInches,
        Width: product.WidthInches,
        Depth: product.DepthInches,
        Weight: product.WeightPounds,
        VendorProductName: product.VendorProductName,
        Description: product.FullDescription,
        IsColdVault: product.IsColdVault,
        IsAlcohol: product.IsAlcohol,
        ProductType: product.ProductType,
        VerificationStatus: product.VerificationStatus
      })
    } else { // pure product create, don't autofill data 
      reset({})
    }
  }, [product, brands, categories, ibcProducts, productSubCategories, productFamilies]);

  useEffect(() => {
    ibcEnterpriseApi.getAllProductFamilies().then(result => {
      const response = result.data;
      setProductFamilies(response);
    })
    ibcEnterpriseApi.getAllProductSubCategories().then(result => {
      const response = result.data;
      setProductSubCategories(response);
    })
  }, []);


  return (
    <main>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>

          <Grid item xs={12} ><h5>Required Fields</h5></Grid>

          <Grid item xs={12} sm={6}><ProductFormTextField name="Item" defaultValue="" control={control} errorMessage="Item is required" /></Grid>
          <Grid item xs={12} sm={6}><ProductFormTextField name="UPCA" defaultValue="" control={control} errorMessage="UPCA must be a 10-12 digit number" /></Grid>
          <Grid item xs={12} sm={6}><VirtualizedAutocomplete name="Brand" options={brands} defaultValue="" errorMessage="Brand is required" control={control} /></Grid>
          <Grid item xs={12} sm={6}><VirtualizedAutocomplete name="Category" options={categories} defaultValue="" errorMessage="Category is required" control={control} /></Grid>
          <Grid item xs={12} sm={6}><VirtualizedAutocomplete name="ProductFamily" options={productFamilies} defaultValue="" errorMessage="ProductFamily is required" control={control} /></Grid>
          <Grid item xs={12} sm={6}><VirtualizedAutocomplete name="Subcategory" options={productSubCategories} defaultValue="" errorMessage="Subcategory is required" control={control} /></Grid>
          <Grid item xs={12} sm={6}><FormControllerSelectField name="IsColdVault" options={trueFalseOptions} defaultValue="" errorMessage="IsColdVault is required" control={control} /></Grid>
          <Grid item xs={12} sm={6}><FormControllerSelectField name="IsAlcohol" options={trueFalseOptions} defaultValue="" errorMessage="IsAlcohol is required" control={control} /></Grid>
          <Grid item xs={12} sm={6}><FormControllerSelectField name="ProductType" options={productTypeOptions} defaultValue="" errorMessage="ProductType is required" control={control} /></Grid>
          <Grid item xs={12} sm={6}><FormControllerSelectField name="VerificationStatus" options={verificationStatusOptions} defaultValue="" errorMessage="ProductType is required" control={control} /></Grid>

          <Grid item xs={12} ><h5 style={{ marginTop: '10px' }}>Additional Fields</h5></Grid>

          <Grid item xs={12} sm={6}><VirtualizedAutocomplete name="IBCProduct" defaultValue="" options={ibcProducts} control={control} /></Grid>
          <Grid item xs={12} sm={6}><ProductFormTextField name="MaterialNumber" defaultValue="" control={control} errorMessage="Material Number must be a number" /></Grid>
          <Grid item xs={12} sm={6}><ProductFormTextField name="CaseQuantity" defaultValue="" control={control} errorMessage="Case Quantity must be a number" /></Grid>
          <Grid item xs={12} sm={6}><ProductFormTextField name="PackType" defaultValue="" control={control} /></Grid>
          <Grid item xs={12} sm={6}><ProductFormTextField name="PackSize" defaultValue="" control={control} /></Grid>
          <Grid item xs={12} sm={6}><ProductFormTextField name="Height" defaultValue="" control={control} errorMessage="Height (inches) must be a number" /></Grid>
          <Grid item xs={12} sm={6}><ProductFormTextField name="Width" defaultValue="" control={control} errorMessage="Width (inches) must be a number" /></Grid>
          <Grid item xs={12} sm={6}><ProductFormTextField name="Depth" defaultValue="" control={control} errorMessage="Depth (inches) must be a number" /></Grid>
          <Grid item xs={12} sm={6}><ProductFormTextField name="Weight" defaultValue="" control={control} errorMessage="Weight (pounds) must be a number" /></Grid>
          <Grid item xs={12} sm={6}><ProductFormTextField name="VendorProductName" defaultValue="" control={control} /></Grid>
          <Grid item xs={12} ><ProductFormTextField className="full-row" name="Description" defaultValue="" control={control} /></Grid>
          <Grid item xs={12} ><button className="submit-btn btn btn-primary" type="submit">{buttonLabel}</button></Grid>
        </Grid>
      </form>
    </main>
  )
}
export default ProductForm;