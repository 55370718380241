import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">

                        <li className="menu-title">{this.props.t('Product Admin Portal')}</li>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-home"></i>
                                <span>{this.props.t('Home')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="/dashboard">{this.props.t('Dashboard')}</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-barcode"></i>
                                <span>{this.props.t('Products')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="/products/search">{this.props.t('Search')}</Link></li>
                                <li><Link to="/products/lookup">{this.props.t('Lookup')}</Link></li>
                                <li><Link to="/products/create">{this.props.t('Create')}</Link></li>
                                <li><Link to="/products/load">{this.props.t('Load')}</Link></li>
                                <li><Link to="/products/bulkupdate">{this.props.t('Bulk Update')}</Link></li>
                                <li><Link to="/products/settings">{this.props.t('Settings')}</Link></li>

                            </ul>
                        </li>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className='bx bxs-cart-download'></i>
                                <span>{this.props.t('Orders')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="/orders/cart">{this.props.t('Cart')}</Link></li>
                                <li><Link to="/orders/history">{this.props.t('History')}</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(SidebarContent));
