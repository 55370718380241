import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';
import { StoreProvider, createStore } from 'easy-peasy';
import model from './model/model';
import authentication from '@kdpw/msal-b2c-react';
import store from './store'

authentication.initialize({
  // you can user your b2clogin.com domain here, setting is optional, will default to this
  instance: 'https://ibcenterpriseb2cad.b2clogin.com/',
  // your B2C tenant, you can also user tenants GUID here
  tenant: 'ibcenterpriseb2cad.onmicrosoft.com',
  // the policy to use to sign in, can also be a sign up or sign in policy
  signInPolicy: 'B2C_1_Signup_Signin',
  // the policy to use for password reset
  resetPolicy: 'mypasswordresetpolicy',
  // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
  applicationId: '74a28f7d-49aa-46ff-add7-e5b553ef493b',
  // where MSAL will store state - localStorage or sessionStorage
  cacheLocation: 'localStorage',
  // the scopes you want included in the access token
  scopes: ['https://ibcenterpriseb2cad.onmicrosoft.com/ibcEnterpriseApi/Products.Read', 'openid', 'profile'],
  // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
  redirectUri: window.location.origin + '/dashboard',
  // optional, the URI to redirect to after logout
  //postLogoutRedirectUri: 'http://myapp.com',
  // optional, default to true, set to false if you change instance
  validateAuthority: false,
  // optional, default to false, set to true if you only want to acquire token silently and avoid redirections to login page
  silentLoginOnly: false
});

const EZPZstore = createStore(model);

const app = (
  <Provider store={store}>
    <StoreProvider store={EZPZstore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StoreProvider>
  </Provider>
);

authentication.run(() => {
  ReactDOM.render(app, document.getElementById('root'));
  serviceWorker.unregister();
});
