import React, { useEffect } from 'react';
import { ibcEnterpriseApi } from '../../apis/ibcEnterpriseApi';
import '../../styles/ProductStyles.css';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import UnavailableProductImage from "../../assets/images/unavailable/product-image-unavailable.png";
import GenericAlert from "../../components/Common/GenericAlert";
import ProductForm from "../../components/Common/Product/ProductForm";
import { useStoreState, useStoreActions } from 'easy-peasy';
import DZPreview from "../../components/Common/DZPreview";
import SweetAlert from "react-bootstrap-sweetalert";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap";


function ProductCreate(props) {

  // Getting state references w/ 'userStoreState'
  const {
    product,
    brands,
    categories,
    ibcProducts,
    galleryImages,
    alert,
    spinner,
    dropZoneImages,
    shouldAutofillProductData,
    images

  } = useStoreState(state => state);


  // Setting and Changing state w/ 'userStoreActions'
  const {
    fetchInitialState,
    setProductId,
    toggleAlert,
    setPrevUPCA,
    setDropZoneImages,
    resetProduct,
    createProduct,
    setGallery

  } = useStoreActions(actions => actions);


  // Effects //

  // componentDidMount
  useEffect(() => {
    resetProduct();
    const payload = {
      id: product.id,
      brands: brands,
      categories: categories,
      ibcProducts: ibcProducts
    }
    fetchInitialState(payload);
  }, [])

  // Set galleryImages
  useEffect(() => {
    setGallery(null);
  }, [images]) // Whenever the images state changes 

  // Handlers //

  const handleFileDrop = (files) => {
    setDropZoneImages(files);
  }

  const handleExistingUPCA = (upca) => {
    ibcEnterpriseApi.getProductByUPCA(upca)
      .then(result => {
        if (result.data) {
          toggleAlert(alert.duplicateUPCA);
          setProductId(result.data.Id);
        }
      })
  }

  const handleEditNavigation = () => {
    props.history.push({
      pathname: '/products/edit/' + product.Id
    });
  }

  const handleSubmit = () => {

    const length = (product.UPCA) ? product.UPCA.length : 0;
    if (length >= 10 && length <= 12) {
      ibcEnterpriseApi.getProductByUPCA(product.UPCA)
        .then(result => {
          if (result.data) { // don't create existing product
            toggleAlert(alert.duplicateUPCA);
            setProductId(result.data.Id);
          } else { // create non-existing product
            var payload = {
              product: product,
              Id: product.Id,
              UPCA: product.UPCA,
              dropZoneImages: dropZoneImages,
              props: props
            }
            createProduct(payload);
          }
        })
    } else {
      toggleAlert(alert.invalidUPCA);
    }
  }

  const handleCancelNavigation = () => {
    setPrevUPCA();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {/* UPCA cannot be updated Alert */}
        <GenericAlert
          {...props}
          alert={alert.duplicateUPCA}
          title="Product UPCA already exists!"
          description="Navigate to edit product?"
          onConfirmTitle="Creation Discarded"
          onConfirmDescription="Navigate to edit pre-existing product"
          onConfirmAction={handleEditNavigation}
          onCancelTitle="Navigation Cancelled"
          onCancelDescription="Please input a unique UPCA"
          onCancelAction={handleCancelNavigation}
        />
        {   /* Conditional Rendering */
          alert.invalidUPCA.display ? (
            <SweetAlert
              title="Invalid value for UPCA!"
              warning
              onConfirm={() => toggleAlert(alert.invalidUPCA)}
            >
              Please provide a 12 digit UPCA number to save product
            </SweetAlert>
          ) : null
        }
        {   /* Conditional Rendering */
          spinner.loadingImages ? (
            <div className="loading-indicator">
              <div>Loading images...</div>
              <div className="spinner-border text-primary m-1">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : null
        }
        <div className="container-fluid">
          <Breadcrumbs title={'Products'} breadcrumbItem={'Create'} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="4">
                      <div className="detail-image" style={{ paddingTop: "25px", paddingLeft: "25px" }}>
                        {   /* Conditional Rendering */
                          (galleryImages.length !== 0 && galleryImages[0].original)
                            ?
                            <ImageGallery showPlayButton={false} items={galleryImages} />
                            :
                            <div className="no-img-container"><img src={UnavailableProductImage} alt='unavailable' /> </div>
                        }
                        <DZPreview
                          onFileDrop={handleFileDrop}
                          files={dropZoneImages}
                        />
                      </div>
                    </Col>
                    <Col xl="8" style={{ paddingTop: "25px", paddingRight: "25px", paddingBottom: "100px" }}>
                      <ProductForm
                        product={product}
                        brands={brands}
                        categories={categories}
                        ibcProducts={ibcProducts}
                        onSave={handleSubmit}
                        buttonLabel="Submit"
                        dataAutofilled={shouldAutofillProductData}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductCreate;