import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SearchProducts from "../../assets/images/backgrounds/history.jpg";
import CreateProducts from "../../assets/images/backgrounds/create.jpeg";
import ViewCart from "../../assets/images/backgrounds/cart.jpg";
import OrderHistory from "../../assets/images/backgrounds/cart.jpg";


//i18n
import { withNamespaces } from 'react-i18next';

const Dashboard = (props) => {

    const handleDashNav = (endpoint) => {
        props.history.push({
            pathname: '/' + endpoint
        })
    }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={props.t('Home')} breadcrumbItem={props.t('Dashboard')} />

                        <Row >
                            <Col xl="2">
                                <row>
                                    <Card style={{ height: "188px" }}>
                                        <CardBody >
                                            <CardTitle className="mb-2 float-sm">
                                                Statistics
                                        </CardTitle>

                                            <div className="clearfix"></div>
                                        </CardBody>
                                    </Card>
                                </row>
                                <row>
                                    <Card style={{ height: "188px" }}>
                                        <CardBody >
                                            <CardTitle className="mb-2 float-sm">
                                                Statistics
                                        </CardTitle>

                                            <div className="clearfix"></div>
                                        </CardBody>
                                    </Card>
                                </row>
                            </Col>
                            <Col xl="4">
                                <Card style={{ height: "400px", backgroundColor: '#90BE6D' }} onClick={() => handleDashNav('products/search')}>
                                    <CardBody>
                                        <CardTitle className="mb-2 float-sm-left">
                                            Search Products
                                        </CardTitle>

                                        <div className="clearfix"></div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4">
                                <Card style={{ height: "400px", backgroundColor: '#F0F2A6' }} onClick={() => handleDashNav('products/create')}>
                                    <CardBody>
                                        <CardTitle className="mb-2 float-sm-left">
                                            Create Products
                                        </CardTitle>
                                        <div className="clearfix"></div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="2">
                                <row>
                                    <Card style={{ height: "188px" }}>
                                        <CardBody >
                                            <CardTitle className="mb-2 float-sm">
                                                Statistics
                                        </CardTitle>

                                            <div className="clearfix"></div>
                                        </CardBody>
                                    </Card>
                                </row>
                                <row>
                                    <Card style={{ height: "188px" }}>
                                        <CardBody >
                                            <CardTitle className="mb-2 float-sm">
                                                Statistics
                                        </CardTitle>

                                            <div className="clearfix"></div>
                                        </CardBody>
                                    </Card>
                                </row>
                            </Col>
                        </Row>
                        <Row >
                            <Col xl="2">
                                <row>
                                    <Card style={{ height: "188px" }}>
                                        <CardBody >
                                            <CardTitle className="mb-2 float-sm">
                                                Statistics
                                        </CardTitle>

                                            <div className="clearfix"></div>
                                        </CardBody>
                                    </Card>
                                </row>
                                <row>
                                    <Card style={{ height: "188px" }}>
                                        <CardBody >
                                            <CardTitle className="mb-2 float-sm">
                                                Statistics
                                        </CardTitle>

                                            <div className="clearfix"></div>
                                        </CardBody>
                                    </Card>
                                </row>
                            </Col>
                            <Col xl="4">
                                <Card style={{ height: "400px", backgroundColor: '#FF8552' }} onClick={() => handleDashNav('orders/cart')}>
                                    <CardBody>
                                        <CardTitle className="mb-2 float-sm-left">
                                            View Cart
                                        </CardTitle>

                                        <div className="clearfix"></div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4">
                                <Card style={{ height: "400px", backgroundColor: '#39A0ED' }} onClick={() => handleDashNav('orders/history')}>
                                    <CardBody>
                                        <CardTitle className="mb-2 float-sm-left">
                                            Order History
                                        </CardTitle>
                                        <div className="clearfix"></div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="2">
                                <row>
                                    <Card style={{ height: "188px" }}>
                                        <CardBody >
                                            <CardTitle className="mb-2 float-sm">
                                                Statistics
                                        </CardTitle>

                                            <div className="clearfix"></div>
                                        </CardBody>
                                    </Card>
                                </row>
                                <row>
                                    <Card style={{ height: "188px" }}>
                                        <CardBody >
                                            <CardTitle className="mb-2 float-sm">
                                                Statistics
                                        </CardTitle>

                                            <div className="clearfix"></div>
                                        </CardBody>
                                    </Card>
                                </row>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>
        );
}

export default withNamespaces()(Dashboard);
