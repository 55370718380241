import React, { useState } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { useStoreState, useStoreActions } from 'easy-peasy';


const GenericAlert = (props) => {

    // Global State references
    const {
        dynamic
    } = useStoreState(state => state);

    // Global State modifiers
    const {
        toggleAlert,
        setAlertMessages
    } = useStoreActions(actions => actions);

    // Local State
    const [displayProceed, toggleDisplayProceed] = useState(false);
    const [displayCancel, toggleDisplayCancel] = useState(false);

    return (
        <>
            {
                // Initial Alert
                props.alert.display ? ( // Display
                    <SweetAlert
                        title={props.title}
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            toggleAlert(props.alert); // Hide
                            toggleDisplayProceed(!displayProceed); // Display Proceed
                            setAlertMessages([
                                props.onConfirmTitle,
                                props.onConfirmDescription
                            ]);
                        }}
                        onCancel={() => {
                            toggleAlert(props.alert); // Hide
                            toggleDisplayCancel(!displayCancel); // Display Cancel
                            setAlertMessages([
                                props.onCancelTitle, 
                                props.onCancelDescription
                            ]);
                        }}
                    >
                        {props.description}
                    </SweetAlert>
                ) : null
            }
            {
                // Sencondary Alert if user presses 'Ok'
                displayProceed ? (
                    <SweetAlert
                        success
                        title={dynamic.title}
                        onConfirm={() => {
                            toggleDisplayProceed(!displayProceed); // Hide Proceed
                            props.onConfirmAction();
                        }}
                    >
                        {dynamic.description}
                    </SweetAlert>
                ) : null
            }
            {
                // Sencondary Alert if user presses 'Cancel'
                displayCancel ? (
                    <SweetAlert
                        error
                        title={dynamic.title}
                        onConfirm={() => {
                            toggleDisplayCancel(!displayCancel); // Hide Cancel
                            props.onCancelAction();
                        }}
                    >
                        {dynamic.description}
                    </SweetAlert>
                ) : null
            }
        </>
    )
};

export default GenericAlert;