import * as msal from "@azure/msal-browser";

// this is the "Client Id" or "App Id" that is assigned to this app when it was registered with Azure AD B2C
const enterpriseWebAdminClientId = '74a28f7d-49aa-46ff-add7-e5b553ef493b';

const b2cPolicies = {
    names: {
        signUp: "B2C_1_Sign_In",
        signUpSignIn: "B2C_1_Signup_Signin",
        forgotPassword: "b2c_1_reset",
        editProfile: "b2c_1_edit_profile"
    },
    authorities: {
        signUp: {
            authority: "https://ibcenterpriseb2cad.b2clogin.com/ibcenterpriseb2cad.onmicrosoft.com/B2C_1_Sign_In",
        },
        signUpSignIn: {
            authority: "https://ibcenterpriseb2cad.b2clogin.com/ibcenterpriseb2cad.onmicrosoft.com/B2C_1_Signup_Signin",
        },
        forgotPassword: {
            authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_reset",
        },
        editProfile: {
            authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile"
        }
    },
    authorityDomain: "ibcenterpriseb2cad.b2clogin.com"
}

const msalConfig = {
    auth: {
        clientId: enterpriseWebAdminClientId, // This is the ONLY mandatory field; everything else is optional.
        authority: b2cPolicies.authorities.signUp.authority, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
        redirectUri: window.location.origin + '/dashboard'
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    }

};




let accountId = "";
let username = "";
let accessToken = "";
                                     
const msalInstance = new msal.PublicClientApplication(msalConfig);

// This function handles the the response from the login redirect
// In other words, this code runs FIRST after the user has logged in and is redirected
// (note: only applies to a LoginRedirect() call NOT a LoginPopup() call)
msalInstance.handleRedirectPromise().then((response) => {
    accountId = response.account.homeAccountId;
    accessToken = response.accessToken;
    // Check if the tokenResponse is null
    // If the tokenResponse !== null, then you are coming back from a successful authentication redirect. 
    // If the tokenResponse === null, you are not coming back from an auth redirect.
}).catch((error) => {
    // handle error, either in the library or coming back from the server
});


const handleResponse = (response) => {
    /**
     * To see the full list of response object properties, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
     */

    if (response !== null) {
        accountId = response.account.homeAccountId;

        //seeProfileRedirect();
    } else {
        // Error Logging in
        //alert('Error Logging In');
    }
}







const azureB2cAuth = {

    // This will redirect the user to the azure b2c login page either via a browser popup window
    // OR a browser redirect
    login: () => {

        const loginRequest = {
            scopes: [enterpriseWebAdminClientId]
        }

        // Login Redirect
        msalInstance.loginRedirect(loginRequest)
            .catch(error => {
                console.log(error);

                // Error handling
                if (error.errorMessage) {
                    // Check for forgot password error
                    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
                        msalInstance.loginPopup(b2cPolicies.authorities.forgotPassword)
                            //.then(response => handlePolicyChange(response));
                    }
                }
            });

        // Login popup 

        //msalInstance.loginPopup(loginRequest)
        //    .then(handleResponse)
        //    .catch(error => {
        //        console.log(error);

        //        // Error handling
        //        if (error.errorMessage) {
        //            // Check for forgot password error
        //            // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        //            if (error.errorMessage.indexOf("AADB2C90118") > -1) {
        //                msalInstance.loginPopup(b2cPolicies.authorities.forgotPassword)
        //                //.then(response => handlePolicyChange(response));
        //            }
        //        }
        //    });
    },

    logout: () => {

        /**
         * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
         */

        // Choose which account to logout from.


        msalInstance.logout();
    },

    // This function will use the MSAL.js library to attempt to fetch a cached account
    // The account is cached after a success user login and is user subsequently to fetch access tokens for API calls
    selectAccount: () => {

        /**
         * See here for more info on account retrieval: 
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
         */

        const currentAccounts = msalInstance.getAllAccounts();

        if (currentAccounts.length === 0) {
            return;
        } else if (currentAccounts.length > 1) {
            // Add your account choosing logic here
            return;
        } else if (currentAccounts.length === 1) {
            accountId = currentAccounts[0].homeAccountId;
            username = currentAccounts[0].username;
            return accountId;
        }
    },

    userIsLoggedIn: () => {

        let currentAccount = msalInstance.getAccountByHomeId(accountId);

        if (currentAccount == null)  {
            return false;
        }
    },

    

    getToken: () => {

        /**
        * See here for more information on account retrieval: 
        * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
        */
        let currentAccount = msalInstance.getAccountByHomeId(accountId);

        var tokenRequest = {
            scopes: ['74a28f7d-49aa-46ff-add7-e5b553ef493b'],
            account: currentAccount
        };

        return msalInstance.acquireTokenSilent(tokenRequest)
            .then((response) => {
                // In case the response from B2C server has an empty accessToken field
                // throw an error to initiate token acquisition
                if (!response.accessToken || response.accessToken === "") {
                    throw new msal.InteractionRequiredAuthError;
                }

                console.log(response.accessToken);
                return response;
            })
            .catch(error => {
                console.log(error);
                console.log("silent token acquisition fails. acquiring token using popup");
                if (error instanceof msal.InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return msalInstance.acquireTokenRedirect(tokenRequest)
                        .then(response => {
                            console.log(response);
                            return response;
                        }).catch(error => {
                            console.log(error);
                        });
                } else {
                    msalInstance.acquireTokenRedirect(tokenRequest);
                }
            });
    }

}

export { azureB2cAuth }