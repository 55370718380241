import React, { useEffect } from "react";
import { TextField } from '@material-ui/core';
import '../../../styles/ProductCreateForm.css';
import FieldError from "./FieldError";
import { Controller } from 'react-hook-form';

/* Props:
 *  className
 *  name
 *  defaultValue
 *  control
 *  rules
 *  error
 *  errorMessage
 * */
function ProductFormTextField(props) {
  const { name, defaultValue, control, errorMessage } = props;

  return (
    <div >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => (<>
          <TextField
            {...field}
            label={name}
            variant="outlined"
            fullWidth
            size="small"
            error={!!error}
          />
          {!!error && <FieldError errorMessage={errorMessage} />}
          </>
        )}
      />
    </div>
  )
}
export default ProductFormTextField;