import React, { useState } from 'react';
import { Col, Card, CardBody, Media, Button } from "reactstrap";
import { DataGrid } from '@mui/x-data-grid';


const ClientSideDataGrid = ({ apiRef, GridHeader, rowData, colData, loading, onSelectionChange, selectionModal = [], history, columnVisibilityModel, onColumnVisibilityModelChange }) => {

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  // If parent doesn't pass columns, columns should default to product properties
  const columns = (colData) ? colData : [
    {
      field: 'image',
      headerName: ' ',
      width: 100,
      headerAlign: 'center',
      renderCell: (params) => (
        <Media src={params.value} style={{ maxHeight: 50, maxWidth: 50, marginLeft: 'auto', marginRight: 'auto' }} thumbnail />
      ),
    },
    { field: 'upca', headerName: 'UPCA', width: 150 },
    { field: 'name', headerName: 'Name', flex: 0.4 },
    { field: 'brand', headerName: 'Brand', width: 150 },
    { field: 'category', headerName: 'Category', width: 150 },
    { field: 'LastModifiedOn', headerName: 'Last Updated', width: 175 },
    {
      field: 'id',
      headerName: 'Actions',
      flex: 0.1,
      // This property allows us to render the cell content using a react component instead of record data
      // The "params" obj is provided by the component and holds data related to the field set in the "field" property
      // Note: It seems that only a single react component can be in the body of the "renderCell" function
      // documentation for this call here https://material-ui.com/components/data-grid/rendering/#render-cell
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            history.push({
              pathname: '/products/detail/' + params.value // Here "params.value" is the value of the "id" field for this record
            })
          }}
        >
          View
        </Button>
      ),
    }
  ]

  const handlePageChange = (pageInfo) => {
    setPageNumber(pageInfo.page);
  }

  return (
    <>
      <Col className="col-12">
        <Card>
          <CardBody>
            <GridHeader />
            <div style={{ height: 750, width: '100%', display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  apiRef={apiRef}
                  rows={rowData.rows}
                  columns={columns}
                  pagination
                  page={pageNumber}
                  pageSize={pageSize}
                  onPageSizeChange={(e) => setPageSize(e.pageSize)}
                  rowsPerPageOptions={[10, 50, 100, 500, rowData.rowCount < 500 ? 1000 : rowData.rowCount]}
                  checkboxSelection
                  rowCount={rowData.rowCount}
                  onPageChange={handlePageChange}
                  loading={loading}
                  selectionModal={selectionModal}
                  onSelectionChange={onSelectionChange}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default ClientSideDataGrid;