import React, { useState, useEffect } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { useStoreState, useStoreActions } from 'easy-peasy';


const PromptAlert = (props) => {

  // Global State references
  const {
    dynamic,
    order
  } = useStoreState(state => state);

  // Global State modifiers
  const {
    toggleAlert,
    setAlertMessages,
    setOrder
  } = useStoreActions(actions => actions);

  // Local State
  const [orderName, setOrderName] = useState("");
  const [displayProceed, toggleDisplayProceed] = useState(false);
  const [displayCancel, toggleDisplayCancel] = useState(false);

  useEffect(() => {
    setOrder({
      Name: "Untitled",
      Status: "CheckedOut"
    })
  }, []);


  // Functions
  const handleSaveName = (e) => {
    setOrder({
      Name: (e.target.value !== "") ? e.target.value : "Untitled",
      Status: "CheckedOut"
    })
  }

  const handleOnCancel = () => {
    setOrder({
      Status: "Active"
    });
    toggleAlert(props.alert);
  }

  return (
    <>
      {props.alert.display ? ( // Display
        <SweetAlert
          showCancel
          title="Please give your order a name"
          cancelBtnBsStyle="danger"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            toggleAlert(props.alert); // Hide
            toggleDisplayProceed(!displayProceed); // Display Proceed
            setAlertMessages([
              "Thank you!",
              "Order saved as: " + order.name
            ]);
          }}
          onCancel={handleOnCancel} // Hide
        >
          <input
            type="text"
            className="form-control"
            placeholder="Enter name of order"
            onChange={handleSaveName}
            required
          />
        </ SweetAlert >
      ) : null}
      {displayProceed ? (
        <SweetAlert
          success
          title={dynamic.title}
          onConfirm={() => {
            toggleDisplayProceed(!displayProceed)
            props.checkoutCart({
              history: props.history,
              order: order
            })
          }}
        >
          {dynamic.description}
        </ SweetAlert >
      ) : null}
    </>
  )
};

export default PromptAlert;