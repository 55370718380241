import React, { useEffect } from 'react';
import { ibcEnterpriseApi } from '../../apis/ibcEnterpriseApi';
import '../../styles/ProductStyles.css';
import { Table } from "reactstrap";
import Editable from "react-bootstrap-editable";
import DZPreview from "../../components/Common/DZPreview";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import UnavailableProductImage from "../../assets/images/unavailable/product-image-unavailable.png";
import Trashcan from '../../assets/images/icons/red-trashcan.png'
import GenericAlert from "../../components/Common/GenericAlert";
import { useStoreState, useStoreActions } from 'easy-peasy';
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import OptimizedPickList from '../../components/Common/OptimizedPicklist'
import { FixedSizeList } from 'react-window';
import ProductForm from '../../components/Common/Product/ProductForm';

const ProductEdit = (props) => {

  // Getting state references w/ 'userStoreState'
  const {
    product,
    brands,
    categories,
    ibcProducts,
    images,
    galleryImages,
    alert,
    spinner,
    dropZoneImages,
  } = useStoreState(state => state);


  // Setting and Changing state w/ 'userStoreActions'
  const {
    fetchInitialState,
    setProductId,
    toggleAlert,
    setDropZoneImages,
    deleteProductImage,
    setGallery,
    setGalleryItem,
    saveProduct,
    setShouldAutofillProductData,
    revertUPCA,
  } = useStoreActions(actions => actions);


  // --- Effects --- //

  useEffect(() => {
    const payload = {
      id: props.match.params.id,
      brands: brands,
      categories: categories,
      ibcProducts: ibcProducts
    }
    fetchInitialState(payload);
  }, []) 

  useEffect(() => {
    setGallery(renderImageWithDeleteButton);
  }, [images]) 


  // --- Handlers --- //

  const handleFileDrop = (files) => {
    setDropZoneImages(files);
  }

  const handleNewUPCA = (upca) => {
    ibcEnterpriseApi.getProductByUPCA(upca)
      .then(result => {
        if (!result.data) {
          toggleAlert(alert.cannotUpdateUPCA);
        }
      })
  }

  const handleExistingUPCA = (upca) => {
    ibcEnterpriseApi.getProductByUPCA(upca)
      .then(result => {
        if (result.data) {
          toggleAlert(alert.duplicateUPCA);
          setProductId(result.data.Id);
        }
      })
  }

  const handleSubmit = () => {

    const length = (product.UPCA) ? product.UPCA.length : 0;
    if (length >= 10 && length <= 12) {
      ibcEnterpriseApi.getProductByUPCA(product.UPCA)
        .then(result => {
          if (result.data) { // data here says product exists and is ready to be updated
            var payload = {
              product: product,
              Id: product.Id,
              UPCA: product.UPCA,
              dropZoneImages: dropZoneImages,
              props: props
            }
            saveProduct(payload);
          } else { // create non-existing product
            toggleAlert(alert.cannotUpdateUPCA);
          }
        })
    } else {
      toggleAlert(alert.invalidUPCA);
    }
  }

  const handleCreateNavigation = () => {
    setShouldAutofillProductData(true);
    props.history.push({
      pathname: '/products/create'
    });
  }

  // --- Renders --- //

  const renderImageWithDeleteButton = (item) => {

    if (galleryImages.length !== 0) {
      return (
        <div className="gallery-face">
          <img className="image-gallery-image" src={item.original} />
          <img className="gallery-face-delete"
            height='30px'
            width='30px'
            src={Trashcan}
            onClick={() => {
              setGalleryItem(item);
              toggleAlert(alert.deleteImage);
            }}
          />
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <GenericAlert
          {...props}
          alert={alert.cannotUpdateUPCA}
          title="Product UPCA cannot be updated!"
          description="Navigate to create product?"
          onConfirmTitle="Update Discarded"
          onConfirmDescription="Navigate to create new product"
          onConfirmAction={handleCreateNavigation}
          onCancelTitle="Navigation Cancelled"
          onCancelDescription="Continue with product edit"
          onCancelAction={revertUPCA}
        />
        <GenericAlert
          {...props}
          alert={alert.deleteImage}
          title="Delete product image?"
          description=""
          onConfirmTitle="Product Image Deleted"
          onConfirmDescription="Continue editing product"
          onConfirmAction={deleteProductImage}
          onCancelTitle="Product image deletion was cancelled"
          onCancelDescription="Continue editing product"
          onCancelAction={() => { }}
        />
        {   /* Conditional Rendering */
          alert.invalidUPCA.display ? (
            <SweetAlert
              title="Invalid value for UPCA!"
              warning
              onConfirm={() => toggleAlert(alert.invalidUPCA)}
            >
              Please provide a 12 digit UPCA number to save product
            </SweetAlert>
          ) : null
        }
        {   /* Conditional Rendering */
          spinner.loadingImages ? (
            <div className="loading-indicator">
              <div>Loading images...</div>
              <div className="spinner-border text-primary m-1">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : null
        }
        <div className="container-fluid">
          <Breadcrumbs title={'Products'} breadcrumbItem={'Edit'} />
          <Row>
            <Col xl="12">
              <Card>
                <Row>
                  <Col xl="4">
                    <div className="detail-image" style={{ paddingTop: "25px", paddingLeft: "25px" }}>
                      {   /* Conditional Rendering */
                        (galleryImages.length !== 0 && galleryImages[0].original)
                          ?
                          <ImageGallery showPlayButton={false} items={galleryImages} />
                          :
                          <img src={UnavailableProductImage} alt='unavailable' />
                      }
                      <DZPreview
                        onFileDrop={handleFileDrop}
                        files={dropZoneImages}
                      />
                    </div>
                  </Col>
                  <Col xl="7">
                    <div className="detail-attribute-container" style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                      <div className="table-responsive">
                        <ProductForm
                          product={product}
                          brands={brands}
                          categories={categories}
                          ibcProducts={ibcProducts}
                          onSave={handleSubmit}
                          buttonLabel="Save"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductEdit;