import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Media } from "reactstrap";
import { useStoreActions, useStoreState } from 'easy-peasy';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { DataGrid } from '@material-ui/data-grid';
//i18n
import { withNamespaces } from 'react-i18next';

const OrderHistory = ({ match, history, t }) => {
    const [searchValue, setSearchValue] = useState((match.params.searchValue) ? match.params.searchValue : '');
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState({ rowCount: 0, rows: [] });
    const [pageNumber, setPageNumber] = useState((match.params.pageNumber) ? match.params.pageNumber : 1);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [retrievedProducts, setRetrievedProducts] = useState([]);
    const [pageSize, setPageSize] = useState(50);

    const { order, orderHistory } = useStoreState(state => state);

    const { replaceCartWith, checkoutCart, fetchOrderHistory, downloadOrderById } = useStoreActions(actions => actions);

    const columns = [
        { field: 'id', headerName: 'Order Id', width: 150 },
        { field: 'name', headerName: 'Cart Name', width: 300 },
        { field: 'itemCount', headerName: 'Number of Items', width: 200 },
        { field: 'size', headerName: 'Order Size (kb)', width: 200 },
        { field: 'createdOn', headerName: 'Created', width: 300 },
        { field: 'downloaded', headerName: 'Downloaded', width: 300 },
        {
            field: 'save',
            headerName: 'Save',
            width: 100,
            // This property allows us to render the cell content using a react component instead of record data
            // The "params" obj is provided by the component and holds data related to the field set in the "field" property
            // Note: It seems that only a single react component can be in the body of the "renderCell" function
            // documentation for this call here https://material-ui.com/components/data-grid/rendering/#render-cell
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => downloadOrderById(
                        { orderId: params.value }
                    )}
                >
                    Download
                </Button>
            ),
        }
    ]

    const handlePageChange = (pageInfo) => {
        setPageNumber(pageInfo.page);
    }

    useEffect(() => {
        
        fetchOrderHistory();
    }, [])

    const loadData = () => {
        // Check to see if we are currently loading a page change
        // NOTE: THIS IS REQUIRED BECAUSE OF A CONFIRMED BUG CAUSING THE COMPONENT TO TRIGGER THIS FUNCTION TWICE ON EACH CLICK
        //       THE SPECIFIC BUG CAUSES THE "PAGECHANGE" EVENT TO FIRE ON EVERY COMPONENT RENDER, EVEN THE INITIAL LOAD
        //       REF: https://github.com/mui-org/material-ui-x/issues/228

        

        var rows = orderHistory.orders;

        setRowData({
            rowCount: order.items.length,
            rows: rows
        })
        setRetrievedProducts(rows);
    }

    useEffect(() => {
        loadData();
    }, [pageNumber, pageSize, orderHistory]) // add ', []' to prevent calling it over and over 


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={t('Orders')} breadcrumbItem={t('History')} />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Order History</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Orders are ready to be downloaded here
                                    </CardSubtitle>
                                    <div style={{ height: 750, width: '100%', display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }}>
                                            <DataGrid
                                                rows={rowData.rows}
                                                columns={columns}
                                                pagination
                                                page={pageNumber}
                                                pageSize={pageSize}
                                                onPageSizeChange={(e) => setPageSize(e.pageSize)}
                                                rowsPerPageOptions={[10, 50, 100, 500, rowData.rowCount < 500 ? 1000 : rowData.rowCount]}
                                                rowCount={rowData.rowCount}
                                                onPageChange={handlePageChange}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </div>
            </div>
        </React.Fragment>
    );
};

export default withNamespaces()(OrderHistory);
