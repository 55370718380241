import React from 'react'
import { CardTitle, CardSubtitle } from "reactstrap"
import OpenDataGridMenuOptionsButton from '../Buttons/OpenDataGridMenuOptionsButton'

const GridHeader = ({ cardTitle, cardSubtitle, onHandleButtonClick, buttonLabel, onHandleDataGridMenuOptionsButtonClick }) => {

  return (
    <>
      <CardTitle>{cardTitle}</CardTitle>
      <CardSubtitle className="mb-3">{cardSubtitle}</CardSubtitle>
      <button
        className="btn btn-primary"
        onClick={onHandleButtonClick}
        style={{ marginBottom: "20px", marginRight: "20px" }}
      >
        {buttonLabel}
      </button>
      {onHandleDataGridMenuOptionsButtonClick &&
        <OpenDataGridMenuOptionsButton onHandleClick={onHandleDataGridMenuOptionsButtonClick} />
      }
    </>
  )
}

export default GridHeader