import React from "react";

const DividerWithText = ({ children }) => {
  const dividerStyle = {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    border: {
      borderBottom: '1px solid #bfbfc1',
      width: '100%',
    },
    content: {
      padding: '0 10px',
      color: '#727273',
    }
  };

  return (
    <div style={dividerStyle.container}>
      <div style={dividerStyle.border} />
      <span style={dividerStyle.content}>{children}</span>
      <div style={dividerStyle.border} />
    </div>
  );
};

export default DividerWithText;
