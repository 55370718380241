import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";
import { useStoreState, useStoreActions } from 'easy-peasy';
import authentication from '@kdpw/msal-b2c-react'
import AdminProfileCoverImage from '../../assets/images/backgrounds/AdminProfileCoverImage.png';
import AdminProfilePicture from '../../assets/images/users/AdminProfilePicture.png';
import { ibcEnterpriseApi } from '../../apis/ibcEnterpriseApi';


const WelcomeComp = (props) => {

  const { user } = useStoreState(state => state);
  const { setAdminUser } = useStoreActions(actions => actions);
  const [username, setUsername] = useState("");
  const [orderCount, setOrderCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  useEffect(() => {
    ibcEnterpriseApi.getTotalOrderCount()
      .then(result => {
        const totalOrderCount = result.data[0];
        setOrderCount(totalOrderCount);
      });
    ibcEnterpriseApi.getTotalProductCount()
      .then(result => {
        const totalProductCount = result.data[0];
        setProductCount(totalProductCount);
      });

    const u = {
      username: authentication.getUserName()
    }
    setUsername(u.username);
    setAdminUser(u);
  }, [authentication]);

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="12">
              <img src={AdminProfileCoverImage} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img src={AdminProfilePicture} alt="" className="img-thumbnail rounded-circle" />
              </div>
              <h5 className="font-size-15 text-truncate">Admin</h5>
              <p className="text-muted mb-0 text-truncate">Data Analyst</p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">{productCount.toLocaleString()}</h5>
                    <p className="text-muted mb-0">Products</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">{orderCount.toLocaleString()}</h5>
                    <p className="text-muted mb-0">Orders</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link to="" className="btn btn-primary waves-effect waves-light btn-sm">View Profile <i className="mdi mdi-arrow-right ml-1"></i></Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default WelcomeComp;
