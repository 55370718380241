import React, { useEffect, useState } from "react";
import '../../../styles/ProductCreateForm.css';
import { useForm, useFormContext, Controller } from 'react-hook-form';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ProductFormTextField from "./ProductFormField";
import Grid from '@mui/material/Grid';
import VirtualizedAutocomplete from '../VirtualizedAutocomplete'
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

// Define your validation schema
const validationSchema = yup.object().shape({
  /*Item: yup.string().required(),
  Brand: yup.object().shape({
    Name: yup.string().required()
  }),
  Category: yup.object().shape({
    Name: yup.string().required()
  }),
  ProductFamily: yup.object().shape({
    Name: yup.string().required()
  }),
  Subcategory: yup.object().shape({
    Name: yup.string().required()
  }),
  IBCProduct: yup.object().shape({
    Name: yup.string().required()
  }),*/
});


function ProductModalForm(props) {
  const { editProduct, setEditProduct, brands, categories, subcategories, productFamilies, ibcProducts, buttonLabel, handleClose } = props;

  // Getting state references w/ 'userStoreState'
  const {
    product,
  } = useStoreState(state => state);


  // Setting and Changing state w/ 'userStoreActions'
  const {
    setProduct,
  } = useStoreActions(actions => actions);

  const onSubmit = (data) => {

    console.log("data")
    console.log(data)
    product.ShortDescription = data.Item;
    product.ProductBrand = data.Brand;
    product.ProductBrandId = data.Brand.Id;
    product.ProductCategory = data.Category;
    product.ProductCategoryId = data.Category.Id;
    product.ProductSubCategory = data.Subcategory;
    product.ProductSubCategoryId = data.Subcategory.Id;
    product.ProductFamily = data.ProductFamily;
    product.ProductFamilyId = data.ProductFamily.Id;
    product.IbcProduct = data?.IBCProduct ? data.IBCProduct : null;
    product.IbcProductId = data?.IBCProduct?.Id ? data.IBCProduct.Id : null;
    setProduct(product);
    props.onSave();
  }

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });

  useEffect(() => {
    console.log("------------->")
    console.log(product)

    if (product && brands.length && categories.length && subcategories.length && productFamilies.length && ibcProducts.length) {
      reset({
        Item: product.ShortDescription,
        Brand: brands.find(brand => brand.Id === product.ProductBrandId),
        Category: categories.find(category => category.Id === product.ProductCategoryId),
        Subcategory: subcategories.find(subCategory => subCategory.Id === product.ProductSubCategoryId),
        ProductFamily: productFamilies.find(productFamily => productFamily.Id === product.ProductFamilyId),
        IBCProduct: ibcProducts.find(ibcProduct => ibcProduct.Id === product.IbcProductId),
      })
      
    } else { // pure product create, don't autofill data 
      reset({})
    }
  }, [product, brands, categories, ibcProducts, subcategories, productFamilies]);

  return (
    <main>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}><ProductFormTextField name="Item" defaultValue="" control={control} errorMessage="Item is required" /></Grid>
          <Grid item xs={12} sm={6}><VirtualizedAutocomplete name="Brand" options={brands} defaultValue="" errorMessage="Brand is required" control={control} /></Grid>
          <Grid item xs={12} sm={6}><VirtualizedAutocomplete name="Category" options={categories} defaultValue="" errorMessage="Category is required" control={control} /></Grid>
          <Grid item xs={12} sm={6}><VirtualizedAutocomplete name="ProductFamily" options={productFamilies} defaultValue="" errorMessage="ProductFamily is required" control={control} /></Grid>
          <Grid item xs={12} sm={6}><VirtualizedAutocomplete name="Subcategory" options={subcategories} defaultValue="" errorMessage="Subcategory is required" control={control} /></Grid>
          <Grid item xs={12} sm={12}><VirtualizedAutocomplete name="IBCProduct" options={ibcProducts} defaultValue="" errorMessage="IBCProduct is required" control={control} /></Grid>
          <Grid item xs={6} ><button className="submit-btn btn btn-secondary" onClick={handleClose}>Close</button></Grid>
          <Grid item xs={6} ><button className="submit-btn btn btn-primary" type="submit">{buttonLabel}</button></Grid>
        </Grid>
      </form>
    </main>
  )
}
export default ProductModalForm;