import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, FormGroup, FormText, CardBody, CardTitle, CardSubtitle, Button, Media } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import UPCListSearch from '../../components/UPCListSearch/UPCListSearch';
import ClientSideDataGrid from '../../components/ClientSideDataGrid/ClientSideDataGrid';
import GridHeader from '../../components/GridHeader/GridHeader';
import SweetAlert from "react-bootstrap-sweetalert";
import { useStoreActions, useStoreState } from 'easy-peasy';
import CSVDropZone from './../../components/Common/CSVDropZone';
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from "react-router-dom";

const ProductLoad = () => {

    let history = useHistory();

    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [rowData, setRowData] = useState({rows: [], rowCount: 0});


    const { dropData, upcList, validData, displayData, invalidDropDataAlert, requestData, responseData, isLoadingCreate } = useStoreState(state => state.CSVDrop);
    const { clearDropData, createMultipleProducts, toggleLoadingCreate } = useStoreActions(actions => actions.CSVDrop);


    const { csvDropFiles, alert } = useStoreState(state => state);
    const { setCSVDropFiles, toggleAlert } = useStoreActions(actions => actions);

    const handleFileDrop = (files) => {
        setCSVDropFiles(files);
    }

    const handleCreateSelectedProducts = () => {
        createMultipleProducts({ requestData: requestData, alertToggle: () => toggleAlert(alert.responseResults) });
    }

    const handlebb = () => {
        console.log("resp data: " + responseData);
    }

    useEffect(() => {
        if (responseData.createdProducts)
            toggleAlert(alert.responseResults);
    }, [responseData])

    useEffect(() => {
        if (displayData)
            setRowData({rows: displayData, rowCount: displayData.length})
    }, [displayData])

    const gridHeader = () => {
        return (
            <GridHeader
                cardTitle="Products"
                cardSubtitle="Load a group of products by drag 'n' dropping or selecting spreadsheet files"
                onHandleButtonClick={handleCreateSelectedProducts}
                buttonLabel="Create Selected Products"
            />
        )
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    {invalidDropDataAlert ? (
                        <SweetAlert
                            title="CSV File Does Not Match Template"
                            onConfirm={() => clearDropData()}
                        ></SweetAlert>
                    ) : null}

                    {alert.responseResults.display ? (
                        <SweetAlert
                            title={`Now Displaying ${responseData.createdProducts.length} out of ${responseData.invalidProducts.length + responseData.createdProducts.length} requested products just created`}
                            onConfirm={() => {
                                toggleAlert(alert.responseResults);
                            }}
                        ></SweetAlert>
                    ) : null}
                    <LoadingOverlay active={isLoadingCreate} spinner text='Creating products...' />

                    <Breadcrumbs title={'Products'} breadcrumbItem={'Load'} />
                    <Row className="justify-content-md-center" >
                        <Col className="col-4" >
                            <CSVDropZone
                                files={csvDropFiles}
                                onFileDrop={handleFileDrop}
                                fileTypes=".csv"
                                header="Drag 'n' drop spreadsheet files or click to select"
                                subheader='(Files saved as "CSV UTF-8 (Comma delimited) (*.csv)")'
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: "28px"}}>
                        <Col className="col-12">
                            <ClientSideDataGrid
                                GridHeader={gridHeader}
                                rowData={rowData}
                                onSelectionChange={(e) => {
                                    setSelectedRowIds(e.rowIds);
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ProductLoad;