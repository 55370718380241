import React from 'react'

const OpenDataGridMenuOptionsButton = ({ onHandleClick }) => {

  return (
    <>
      <button
        className="btn btn-primary"
        onClick={onHandleClick}
        style={{ marginBottom: "20px" }}
      >
        Open Manage Columns Menu
      </button>
    </>
  )

}

export default OpenDataGridMenuOptionsButton