import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Media } from "reactstrap";
import { useStoreActions, useStoreState } from 'easy-peasy';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { DataGrid } from '@material-ui/data-grid';
import PromptAlert from "../../components/Common/PromptAlert";

//i18n
import { withNamespaces } from 'react-i18next';
import { ibcEnterpriseApi } from '../../apis/ibcEnterpriseApi';

const OrderItem = ({ match, history, t }) => {
  const [searchValue, setSearchValue] = useState((match.params.searchValue) ? match.params.searchValue : '');
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({ rowCount: 0, rows: [] });
  const [pageNumber, setPageNumber] = useState((match.params.pageNumber) ? match.params.pageNumber : 1);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [retrievedProducts, setRetrievedProducts] = useState([]);
  const [pageSize, setPageSize] = useState(50);

  const {
    cart,
    alert,
    order
  } = useStoreState(state => state);

  const {
    deleteOrderItemsRemovedFromCart,
    replaceCartWith,
    checkoutCart,
    toggleAlert
  } = useStoreActions(actions => actions);

  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      width: 100,
      headerAlign: 'center',
      renderCell: (params) => (
        <Media src={params.value} style={{ maxHeight: 50, maxWidth: 50, marginLeft: 'auto', marginRight: 'auto' }} thumbnail />
      ),
    },
    { field: 'upca', headerName: 'UPCA', width: 200 },
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'brand', headerName: 'Brand', width: 200 },
    { field: 'category', headerName: 'Category', width: 200 },
    { field: 'description', headerName: 'ShortDescription', width: 400 },
    {
      field: 'id',
      headerName: 'Actions',
      width: 100,
      // This property allows us to render the cell content using a react component instead of record data
      // The "params" obj is provided by the component and holds data related to the field set in the "field" property
      // Note: It seems that only a single react component can be in the body of the "renderCell" function
      // documentation for this call here https://material-ui.com/components/data-grid/rendering/#render-cell
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            history.push({
              pathname: '/products/detail/' + params.value // Here "params.value" is the value of the "id" field for this record
            })
          }}
        >
          View
        </Button>
      ),
    }
  ]

  const handlePageChange = (pageInfo) => {
    setPageNumber(pageInfo.page);
  }

  const handleCheckout = () => {
    toggleAlert(alert.checkoutCart)
  }

  const handleRemoveFromCart = () => {

    /// TODO (ON MONDAY) figure out Id case fix to delete items in database

    // TODO: Add logic to capture 'items to delete rows'
    const selectedRows = retrievedProducts.filter(product => selectedRowIds.includes(String(product.id)));
    const reducedRows = cart.items.filter(row => !selectedRows.includes(row));
    const removedRows = cart.items.filter(row => selectedRows.includes(row));
    const removedOrderItemIds = removedRows.map(row => row.orderItemId);

    // Items not in state must be deleted
    ibcEnterpriseApi.deleteMultipleOrderItems(removedOrderItemIds);


    replaceCartWith({ OrderItems: reducedRows });
    setRowData({
      rows: reducedRows,
      rowCount: reducedRows.length
    })
  }

  const loadData = () => {
    // Check to see if we are currently loading a page change
    // NOTE: THIS IS REQUIRED BECAUSE OF A CONFIRMED BUG CAUSING THE COMPONENT TO TRIGGER THIS FUNCTION TWICE ON EACH CLICK
    //       THE SPECIFIC BUG CAUSES THE "PAGECHANGE" EVENT TO FIRE ON EVERY COMPONENT RENDER, EVEN THE INITIAL LOAD
    //       REF: https://github.com/mui-org/material-ui-x/issues/228
    if (loading === true) return;

    setLoading(true);

    var rows = []
    var startIndex = ((pageNumber - 1) * pageSize)
    var endIndex = startIndex + pageSize
    var cartItemCount = cart.items.length;
    // we want to take the smaller value to ensure the range is valid
    endIndex = (endIndex < cartItemCount) ? endIndex : cartItemCount;
    if (cart.items) {
      const pageItems = cart.items.slice(startIndex, endIndex)
      rows = pageItems.map(item => item);
    }

    setRowData({
      rowCount: cart.items.length,
      rows: rows
    })
    setRetrievedProducts(rows);
    setLoading(false);
  }

  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, cart])


  return (
    <React.Fragment>
      <PromptAlert alert={alert.checkoutCart} checkoutCart={checkoutCart} history={history} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Orders')} breadcrumbItem={t('Cart')} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Cart Preview</CardTitle>
                  <CardSubtitle className="mb-3">
                    Preview your cart before checking out
                  </CardSubtitle>
                  <div >
                    <button className="btn btn-danger float-left" onClick={handleRemoveFromCart} style={{ marginBottom: "1rem" }}>Remove Selected Items From Cart</button>
                    <button className="btn btn-success float-right" onClick={handleCheckout} style={{ marginBottom: "1rem" }}>Checkout</button>
                  </div>
                  <div style={{ height: 750, width: '100%', display: 'flex' }}>
                    <div style={{ flexGrow: 1 }}>
                      <DataGrid
                        rows={rowData.rows}
                        columns={columns}
                        pagination
                        page={pageNumber}
                        pageSize={pageSize}
                        onPageSizeChange={(e) => setPageSize(e.pageSize)}
                        rowsPerPageOptions={[10, 50, 100, 500, rowData.rowCount < 500 ? 1000 : rowData.rowCount]}
                        checkboxSelection
                        rowCount={rowData.rowCount}
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        loading={loading}
                        onSelectionChange={(e) => {
                          setSelectedRowIds(e.rowIds);
                        }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

    </React.Fragment>
  );
};

export default withNamespaces()(OrderItem);
