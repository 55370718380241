import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, FormGroup, FormText, CardBody, CardTitle, CardSubtitle, Button, Media } from "reactstrap";
import { FaEye, FaEdit } from 'react-icons/fa';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import UPCListSearch from '../../components/UPCListSearch/UPCListSearch';
import ClientSideDataGrid from '../../components/ClientSideDataGrid/ClientSideDataGrid';
import GridHeader from '../../components/GridHeader/GridHeader';
import SweetAlert from "react-bootstrap-sweetalert";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useGridApiRef, GridPreferencePanelsValue } from '@mui/x-data-grid';


const ProductLookup = ({ history }) => {

  const [loading, setLoading] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [retrievedProducts, setRetrievedProducts] = useState([]);
  const apiRef = useGridApiRef();

  const { order, cart, upcSearchList, rowData, alert, dynamic, product } = useStoreState(state => state);
  const {
    createOrderItems,
    fetchLatestOrderId,
    fetchProductsByUpcList,
    toggleAlert,
    setProduct,
    toggleShouldResetProduct,
    setUpcSearchList
  } = useStoreActions(actions => actions);

  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      width: 100,
      headerAlign: 'center',
      renderCell: (params) => (
        <Media src={params.value} style={{ maxHeight: 50, maxWidth: 50, marginLeft: 'auto', marginRight: 'auto' }} thumbnail />
      ),
    },
    { field: 'upca', headerName: 'UPCA', flex: 0.1 },
    { field: 'name', headerName: 'Name', flex: 0.1 },
    { field: 'ibcProduct', headerName: 'IBC Product', flex: 0.1 },
    { field: 'family', headerName: 'Family', flex: 0.1 },
    { field: 'subCategory', headerName: 'Subcategory', flex: 0.1 },
    { field: 'brand', headerName: 'Brand', flex: 0.1 },
    { field: 'category', headerName: 'Category', flex: 0.1 },
    { field: 'match', headerName: 'Match', flex: 0.1 },
    { field: 'isColdVault', headerName: 'Cold Vault', flex: 0.1 },
    { field: 'createdBy', headerName: 'Created By', flex: 0.1 },
    { field: 'createdOn', headerName: 'Created On', flex: 0.1 },
    { field: 'lastModifiedBy', headerName: 'Last Updated By', flex: 0.1 },
    { field: 'lastModifiedOn', headerName: 'Last Updated On', flex: 0.1 },
    {
      field: 'id',
      headerName: 'Action',
      headerAlign: 'center',
      flex: 0.1,
      // This property allows us to render the cell content using a react component instead of record data
      // The "params" obj is provided by the component and holds data related to the field set in the "field" property
      // Note: It seems that only a single react component can be in the body of the "renderCell" function
      // documentation for this call here https://material-ui.com/components/data-grid/rendering/#render-cell
      renderCell: (params) => {
        if (params.value < 0) return ( // "params.value" which is the value of the "id" field for this record
          <div className="text-center" style={{ width: "90%" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 16, minWidth: "75px" }}
              onClick={() => handleCreateProduct(params)}
            >
              Create
            </Button>
          </div>
        )
        return (
          <div className="text-center" style={{ width: "100%" }}>
            <Button
              color="primary"
              style={{ marginRight: '10px' }}
              onClick={() => handleViewProduct(params.value)}>
              <FaEye />
            </Button>
            <Button
              color="secondary"
              onClick={() => handleEditProduct(params.value)}>
              <FaEdit />
            </Button>
          </div>
        )
      },
    }
  ]

  const handleViewProduct = (id) => {
    history.push({
      pathname: '/products/detail/' + id
    })
  }

  const handleEditProduct = (id) => {
    history.push({
      pathname: '/products/edit/' + id
    })
  }

  const handleCreateProduct = (params) => {

    var upc = params.row.upca;

    product.UPCA = upc;
    setProduct(product)
    toggleShouldResetProduct(); // turns it to false
    history.push({
      pathname: '/products/create/'
    })
  }

  const handleUPCListSearch = () => {
    fetchProductsByUpcList(upcSearchList);
  }

  const handleAddToCart = () => {
    const selectedRows = retrievedProducts.filter(product => selectedRowIds.includes(String(product.id)));
    const productIdsInCart = cart.items.map(item => item.id);
    const uniqueRows = selectedRows.filter(row => !productIdsInCart.includes(row.id));
    const payload = {
      products: uniqueRows,
      latestOrderId: order.latestId,
      isCartEmpty: order.isCartEmpty,
      status: order.status,
      orderItems: order.items
    }
    createOrderItems(payload);
  }

  const handleDataGridMenuOptionsButtonClick = () => {
    if (apiRef.current) {
      apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
    }
  }

  useEffect(() => {
    setRetrievedProducts(rowData.rows);
  }, [rowData])

  useEffect(() => {
    fetchLatestOrderId()
  }, [])

  const gridHeader = () => {
    return (
      <GridHeader
        cardTitle="Products"
        cardSubtitle="Lookup product by pasting a list of UPCs in the search area"
        onHandleButtonClick={handleAddToCart}
        buttonLabel="Add Selected Products to Cart"
        onHandleDataGridMenuOptionsButtonClick={handleDataGridMenuOptionsButtonClick}
      />
    )
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    upca: false,
    name: false,
    match: false
  });

  useEffect(() => {
    // Function to retrieve and parse JSON from local storage
    const loadStateFromLocalStorage = () => {
      try {
        const storedData = localStorage.getItem('columnPreferences');
        if (storedData) {
          // Parse the JSON string into a JavaScript object
          const parsedData = JSON.parse(storedData);
          setColumnVisibilityModel(parsedData);
        }
        else {
          const stateJSON = JSON.stringify(columnVisibilityModel);
          localStorage.setItem('columnPreferences', stateJSON);
        }
      } catch (error) {
        // Handle any potential errors while parsing or loading data
        console.error('Error loading data from local storage:', error);
      }
    };

    // Call the function to load data when the component mounts
    loadStateFromLocalStorage();
  }, []);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">

          {alert.lookupResults.display ? (
            <SweetAlert
              title={dynamic.title}
              onConfirm={() => {
                toggleAlert(alert.lookupResults);
                setUpcSearchList([]);
              }}
            ></SweetAlert>
          ) : null}

          <Breadcrumbs title={'Products'} breadcrumbItem={'Lookup'} />
          <Row className="justify-content-md-center">
            <Col className="col-4">
              <UPCListSearch handleUPCListSearch={handleUPCListSearch} />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <ClientSideDataGrid
                apiRef={apiRef}
                GridHeader={gridHeader}
                rowData={rowData}
                colData={columns}
                loading={loading}
                onSelectionChange={(e) => {
                  setSelectedRowIds(e.rowIds);
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                  setColumnVisibilityModel(newModel)
                  const stateJSON = JSON.stringify(newModel);
                  localStorage.setItem('columnPreferences', stateJSON);
                }}
              />
            </Col>
          </Row>

        </div>
      </div>
    </>
  )
}

export default ProductLookup;