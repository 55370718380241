import React, { Component } from 'react';
import { azureB2cAuth } from '../../auth/azureB2cAuth';


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {}

      
    }


    componentDidMount() {
        let currentAccount = azureB2cAuth.selectAccount();
        if (currentAccount == null) azureB2cAuth.login();
        else {
            this.props.history.push("/dashboard");
        }
    }

    render() {

        return (
            <React.Fragment>
                <h5>Redirecting to login...</h5>
            </React.Fragment>
        );
    }
}



export default Login;

