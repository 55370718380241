import React, { Component } from "react";

import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap";
import { Link } from "react-router-dom";

class LatestTranaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: [
                { orderId: "#SK2540", name: "Order 3", count: "410", created: "3/31/2021", downloaded: "", save: "" },
                { orderId: "#SK2542", name: "Order 2", count: "16", created: "3/29/2021", downloaded: "", save: "" },
                { orderId: "#SK2543", name: "Order 1", count: "1521", created: "3/29/2021", downloaded: "", save: "" }
            ]
        };
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">
                            Recent Orders
                        </CardTitle>
                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap mb-0">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Order ID</th>
                                        <th>Cart Name</th>
                                        <th>Number of Items</th>
                                        <th>Created</th>
                                        <th>Downloaded</th>
                                        <th>Save</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.transactions.map((transaction, key) =>
                                            <tr key={"_tr_" + key}>
                                                <td><Link to="#" className="text-body font-weight-bold"> {transaction.orderId} </Link> </td>
                                                <td>{transaction.name}</td>
                                                <td>
                                                    {transaction.count}
                                                </td>
                                                <td>
                                                    {transaction.created}
                                                </td>
                                                <td>
                                                    {transaction.donloaded}
                                                </td>
                                                <td>
                                                    <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light" onClick={this.togglemodal}>
                                                        Download
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default LatestTranaction;
