import React, { useEffect, useState } from 'react';
import { ibcEnterpriseApi } from '../../apis/ibcEnterpriseApi';
import { Row, Col, Card, CardBody, CardTitle, Modal, Button, Media } from "reactstrap";
// import { useHistory } from "react-router-dom";
import { DataGrid } from '@material-ui/data-grid';
import { FaEye, FaEdit } from 'react-icons/fa';

import { useStoreActions, useStoreState } from 'easy-peasy';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import ProductSearchFilter from '../../components/Common/Filters/ProductSearchFilters'
import { useGridApiRef, GridPreferencePanelsValue } from '@mui/x-data-grid';
import GridHeader from '../../components/GridHeader/GridHeader';
import ClientSideDataGrid from '../../components/ClientSideDataGrid/ClientSideDataGrid';
import ProductEditModal from '../../components/Modals/ProductEditModal';


const ProductSearch = ({ match, history }) => {

  const [searchValue, setSearchValue] = useState((match.params.searchValue) ? match.params.searchValue : '');
  const [rowData, setRowData] = useState({ rowCount: 0, rows: [] });
  const [pageNumber, setPageNumber] = useState((match.params.pageNumber) ? match.params.pageNumber : 1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [retrievedProducts, setRetrievedProducts] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const apiRef = useGridApiRef();

  const [editProduct, setEditProduct] = useState(null);
  const [isProductEditLoading, setIsProductEditLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const { order, brands, categories, subcategories, productFamilies, ibcProducts, cart, product } = useStoreState(state => state);
  const {
    fetchInitialState,
    createOrderItems,
    fetchLatestOrderId,
    setProductFromSearch,
    toggleShouldResetProduct,
    editProductCommand
  } = useStoreActions(actions => actions);

  const [filterOptions, setFilterOptions] = useState({
    "IbcProductId": null,
    "BrandId": null,
    "CategoryId": null,
    "FamilyId": null,
    "SubCategoryId": null
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    upca: false,
    name: false,
    match: false
  });

  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      width: 100,
      headerAlign: 'center',
      renderCell: (params) => (
        <Media src={params.value} style={{ maxHeight: 50, maxWidth: 50, marginLeft: 'auto', marginRight: 'auto' }} thumbnail />
      ),
    },
    { field: 'upca', headerName: 'UPCA', flex: 0.1 },
    { field: 'name', headerName: 'Name', flex: 0.1 },
    { field: 'ibcProduct', headerName: 'IBC Product', flex: 0.1 },
    { field: 'family', headerName: 'Family', flex: 0.1 },
    { field: 'subCategory', headerName: 'Subcategory', flex: 0.1 },
    { field: 'brand', headerName: 'Brand', flex: 0.1 },
    { field: 'category', headerName: 'Category', flex: 0.1 },
    { field: 'isColdVault', headerName: 'Cold Vault', flex: 0.1 },
    { field: 'createdBy', headerName: 'Created By', flex: 0.1 },
    { field: 'createdOn', headerName: 'Created On', flex: 0.1 },
    { field: 'lastModifiedBy', headerName: 'Last Updated By', flex: 0.1 },
    { field: 'lastModifiedOn', headerName: 'Last Updated On', flex: 0.1 },
    {
      field: 'id',
      headerName: 'Action',
      headerAlign: 'center',
      flex: 0.1,
      // This property allows us to render the cell content using a react component instead of record data
      // The "params" obj is provided by the component and holds data related to the field set in the "field" property
      // Note: It seems that only a single react component can be in the body of the "renderCell" function
      // documentation for this call here https://material-ui.com/components/data-grid/rendering/#render-cell
      renderCell: (params) => {
        if (params.value < 0) return ( // "params.value" which is the value of the "id" field for this record
          <div className="text-center" style={{ width: "90%" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 16, minWidth: "75px" }}
              onClick={() => handleCreateProduct(params)}
            >
              Create
            </Button>
          </div>
        )
        return (
          <div className="text-center" style={{ width: "100%" }}>
            <Button
              color="primary"
              style={{ marginRight: '10px' }}
              onClick={() => handleViewProduct(params.value)}>
              <FaEye />
            </Button>
            <Button
              color="secondary"
              onClick={() => handleEditProduct(params.value)}>
              <FaEdit />
            </Button>
          </div>
        )
      },
    }
  ]

  const handleViewProduct = (id) => {
    history.push({
      pathname: '/products/detail/' + id
    })
  }

  const handleEditProduct = (id) => {
    const productToEdit = retrievedProducts.find(product => product.id == id);
    setEditProduct(productToEdit)
    setProductFromSearch(productToEdit)
    setOpenEditModal(true)
  }


  const handleSubmit = async () => {
    try {
      const productResult = await ibcEnterpriseApi.getProductByUPCA(product.UPCA);
      if (productResult.data) { // data here says product exists and is ready to be updated
        await ibcEnterpriseApi.updateProduct(product);
        loadGridData();
        setOpenEditModal(false);
        // need to deselect all rows
        setSelectedRows([])
      }
    } catch (error) {
      // Handle error appropriately
      console.error('An error occurred:', error);
    }
  };


  const handleCreateProduct = (params) => {

    var upc = params.row.upca;

    product.UPCA = upc;
    setProductFromSearch(product)
    toggleShouldResetProduct(); // turns it to false
    history.push({
      pathname: '/products/create/'
    })
  }

  const loadGridData = () => {
    // Check to see if we are currently loading a page change
    // NOTE: THIS IS REQUIRED BECAUSE OF A CONFIRMED BUG CAUSING THE COMPONENT TO TRIGGER THIS FUNCTION TWICE ON EACH CLICK
    //       THE SPECIFIC BUG CAUSES THE "PAGECHANGE" EVENT TO FIRE ON EVERY COMPONENT RENDER, EVEN THE INITIAL LOAD
    //       REF: https://github.com/mui-org/material-ui-x/issues/228
    if (loading === true) return;

    setLoading(true);

    ibcEnterpriseApi.searchProducts(searchValue, pageNumber, pageSize, filterOptions).then(result => {

      var searchResultData = result.data[0];
      var totalRowCount = searchResultData.TotalRecordCount;

      var rows = [];

      history.push({
        pathname: '/products/search/' + searchValue + '/' + pageNumber
      })

      searchResultData.Products.forEach(function (product) {
        let newRow = {};

        newRow.id = product.Id;
        newRow.upca = product.UPCA;
        newRow.name = product.ShortDescription;
        newRow.isColdVault = product.IsColdVault;
        newRow.subCategory = (product.ProductSubCategory) ? product.ProductSubCategory.Name : "No SubCategory";
        newRow.subCategoryId = (product.ProductSubCategory) ? product.ProductSubCategory.Id : null;
        newRow.ibcProduct = (product.IbcProduct) ? product.IbcProduct.Name : "No IbcProduct";
        newRow.ibcProductId = (product.IbcProduct) ? product.IbcProduct.Id : null;
        newRow.family = (product.ProductFamily) ? product.ProductFamily.Name : "No Family";
        newRow.familyId = (product.ProductFamily) ? product.ProductFamily.Id : null;
        newRow.brand = (product.ProductBrand) ? product.ProductBrand.Name : "No Brand";
        newRow.brandId = (product.ProductBrand) ? product.ProductBrand.Id : null;
        newRow.category = (product.ProductCategory) ? product.ProductCategory.Name : "No Category";
        newRow.categoryId = (product.ProductCategory) ? product.ProductCategory.Id : null;
        newRow.description = product.FullDescription;
        if (product.CreatedOn != null) newRow.createdOn = product.CreatedOn.substring(0, 10);
        if (product.CreatedBy != null) newRow.createdBy = product.CreatedBy.substring(0, 10);
        if (product.LastModifiedBy != null) newRow.lastModifiedBy = product.LastModifiedBy.substring(0, 10);
        if (product.LastModifiedOn != null) {
          var modifiedTime = new Date(product.LastModifiedOn);
          modifiedTime.setHours(modifiedTime.getHours() - 5);
          newRow.lastModifiedOn = modifiedTime.toLocaleDateString() + " " + modifiedTime.toLocaleTimeString();
        }
        if (product.Images && product.Images.length > 0) {
          let imageUrl = product.Images[0].Url;
          newRow.image = imageUrl;
        }
        else newRow.image = "No Image";

        //newRow.clickEvent = (rowData) => ()

        rows.push(newRow);
      })

      setRowData({
        rowCount: totalRowCount,
        rows: rows
      })
      setSelectedRows([])
      setRetrievedProducts(rows);
      setLoading(false);
    });
  }

  const handleSearchClick = () => {
    if (match.params.searchValue !== searchValue && pageNumber !== 1) setPageNumber(1);
    else loadGridData();
  }

  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value);
  }

  const handleAddToCart = () => {
    const selectedRows = retrievedProducts.filter(product => selectedRowIds.includes(String(product.id)));
    const productIdsInCart = cart.items.map(item => item.id);
    const uniqueRows = selectedRows.filter(row => !productIdsInCart.includes(row.id));
    const payload = {
      products: uniqueRows,
      latestOrderId: order.latestId,
      isCartEmpty: order.isCartEmpty,
      status: order.status,
      orderItems: order.items
    }
    createOrderItems(payload);
  }

  const handleDataGridMenuOptionsButtonClick = () => {
    if (apiRef.current) {
      apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
    }
  }

  const gridHeader = () => {
    return (
      <GridHeader
        cardTitle="Products"
        cardSubtitle="Lookup product by pasting a list of UPCs in the search area"
        onHandleButtonClick={handleAddToCart}
        buttonLabel="Add Selected Products to Cart"
        onHandleDataGridMenuOptionsButtonClick={handleDataGridMenuOptionsButtonClick}
      />
    )
  }

 /* const handleOnSave = () => {
    setIsProductEditLoading(true);
  }

  useEffect(() => {
    if (isProductEditLoading) {
      console.log(editProduct)
      console.log("editProduct")
      ibcEnterpriseApi.updateProduct(editProduct).then(() => setIsProductEditLoading(false))
    }
  }, [editProduct, isProductEditLoading])*/


  useEffect(() => {
    fetchLatestOrderId()
  }, [])

  useEffect(() => {
    loadGridData()
  }, [filterOptions])

  useEffect(() => {
    loadGridData()
  }, [pageNumber, pageSize])

  useEffect(() => {
    const payload = {}
    fetchInitialState(payload);
  }, []) 

  useEffect(() => {
    // Function to retrieve and parse JSON from local storage
    const loadStateFromLocalStorage = () => {
      try {
        const storedData = localStorage.getItem('columnPreferences');
        if (storedData) {
          // Parse the JSON string into a JavaScript object
          const parsedData = JSON.parse(storedData);
          setColumnVisibilityModel(parsedData);
        }
        else {
          const stateJSON = JSON.stringify(columnVisibilityModel);
          localStorage.setItem('columnPreferences', stateJSON);
        }
      } catch (error) {
        // Handle any potential errors while parsing or loading data
        console.error('Error loading data from local storage:', error);
      }
    };

    // Call the function to load data when the component mounts
    loadStateFromLocalStorage();
  }, []);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={'Products'} breadcrumbItem={'Search'} />
          <ProductEditModal
            showModal={openEditModal}
            setShowModal={setOpenEditModal}
            editProduct={editProduct}
            setEditProduct={setEditProduct}
            onSave={handleSubmit}
            buttonLabel="Save"
            brands={brands}
            categories={categories}
            subcategories={subcategories}
            productFamilies={productFamilies}
            ibcProducts={ibcProducts}
          />
          <Row className="mb-4">
            <Col className="col-4"></Col>
            <Col className="col-4">
              <div className="input-group">

                <input id="search-products-btn" className="form-control form-control-lg" type="text" placeholder="Search Products" value={searchValue} onChange={handleSearchValueChange} />
                <div className="input-group-append">
                  <button className="btn btn-primary" onClick={handleSearchClick} >Search</button>
                </div>
              </div>
            </Col>
            <Col className="col-4"></Col>
          </Row>
          <Row className="mb-4">
            <ProductSearchFilter setFilterOptions={setFilterOptions} />
          </Row>
          <Row>
            <Col className="col-12">
              <ClientSideDataGrid
                apiRef={apiRef}
                GridHeader={gridHeader}
                rowData={rowData}
                colData={columns}
                loading={loading}
                selectionModal={selectedRows}
                onSelectionChange={(e) => {
                  setSelectedRows(e);
                  setSelectedRowIds(e.rowIds);
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                  setColumnVisibilityModel(newModel)
                  const stateJSON = JSON.stringify(newModel);
                  localStorage.setItem('columnPreferences', stateJSON);
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ProductSearch;