import React, { useState, useEffect } from 'react';
import { Row } from "reactstrap";
import { useStoreActions, useStoreState } from 'easy-peasy';


const UPCListSearch = ({ handleUPCListSearch }) => {

    // Local state
    const [upcList, setUpcList] = useState([])

    // Global state
    const { upcSearchList } = useStoreState(state => state);

    // Global actions
    const { setUpcSearchList } = useStoreActions(actions => actions);

    // handle onChange event for upc list textarea
    const handlePaste = (event) => {
        setUpcList(event.target.value.split('\n'));
    }

    // handle onClick event for search button
    const handleSearch = () => {
        setUpcSearchList({ upcList: upcList });
    }

    // handleUPCListSearch once upcSearchList gets updated
    useEffect(() => {
        if (upcSearchList?.length > 0) handleUPCListSearch();
    }, [upcSearchList])

    return (
        <>
            <div className="form-group">
                <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="8"
                    style={{ minHeight: '100px' }}
                    placeholder="Paste your UPC list here"
                    onChange={handlePaste}
                />
            </div>
            <Row className="float-right" style={{ marginBottom: "20px" }} >
                <button className="btn btn-danger">Clear</button>
                <button className="btn btn-primary" style={{ marginLeft: "16px", marginRight: "12px" }} onClick={handleSearch}>Search</button>
            </Row>
        </>
    )
}

export default UPCListSearch;