import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, FormGroup, FormText, CardBody, CardTitle, CardSubtitle, Button, Media } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import LoadingOverlay from 'react-loading-overlay';
import Dropzone from 'react-dropzone';
import DragNDropIcon from '../../assets/images/icons/upload-image-icon.png'
import { ibcEnterpriseApi } from '../../apis/ibcEnterpriseApi';
import GridHeader from '../../components/GridHeader/GridHeader';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import { Check, Close, Info, Upload, Download } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

const ProductBulkUpdate = () => {

    const [failureUpdatesRows, setFailureUpdatesRows] = React.useState(() => []);
    const [successUpdatesRows, setSuccessUpdatesRows] = React.useState(() => []);
    const [newProductsRows, setNewProductsRows] = React.useState(() => []);

    const [numberOfFailedUpdates, setNumberOfFailedUpdates] = useState('Not Submitted');
    const [numberOfSuccessUpdates, setNumberOfSuccessUpdates] = useState('Not Submitted');
    const [numberOfNoChangeUpdates, setNumberOfNoChangeUpdates] = useState('Not Submitted');
    const [numberOfNewProducts, setNumberOfNewProducts] = useState('Not Submitted');
    const [updateProductMetadataChk, setUpdateProductMetadataChk] = useState(false);
    const [showSuccessfulUpdateMessage, setShowSuccessfulUpdateMessage] = React.useState(false);
    const [showFailedUpdateMessage, setShowFailedUpdateMessage] = React.useState(false);
    const [submitBtnDisabled, setSubmitBtnDisabled] = React.useState(true);
    const [loadingBarDisabled, setLoadingBarDisabled] = React.useState(true);


    const [showNoChangeUpdateMessage, setShowNoChangeUpdateMessage] = React.useState(false);
    const [fileSubmissionResultMessage, setFileSubmissionResultMessage] = useState('');
    const [submittedFile, setSubmittedFile] = useState(null);

    const onDrop = (files) => {

        setLoadingBarDisabled(false);
        setSubmittedFile(files[0]);

        var formData = new FormData();
        formData.append('file', files[0]);

        ibcEnterpriseApi.bulkUpdateProductsFromFile(formData, updateProductMetadataChk, false).then(result => {
            setLoadingBarDisabled(true);

            const response = result.data[0];
            setNumberOfFailedUpdates(response.FailureDetails.length);
            setNumberOfSuccessUpdates(response.SuccessDetails.length);
            setNumberOfNoChangeUpdates(response.NoChangeDetails.length);
            setNumberOfNewProducts(response.NewProductDetails.length);

            setFailureUpdatesRows(response.FailureDetails);
            setSuccessUpdatesRows(response.SuccessDetails);
            setSuccessUpdatesRows(response.SuccessDetails);
            setNewProductsRows(response.NewProductDetails);

            if (response.FailureDetails.length == 0 && response.SuccessDetails.length == 0) {
                setFileSubmissionResultMessage('No Updates Required');
                setShowFailedUpdateMessage(false);
                setShowSuccessfulUpdateMessage(false);
                setShowNoChangeUpdateMessage(true);
            }
            else if (response.FailureDetails.length > 0) {
                setFileSubmissionResultMessage('Errors Found. No Updates Occurred. Fix all failures below and resubmit file');
                setShowSuccessfulUpdateMessage(false);
                setShowNoChangeUpdateMessage(false);
                setShowFailedUpdateMessage(true);
            }
            else if (response.SuccessDetails.length > 0) {
                setFileSubmissionResultMessage('Ready to make ' + response.SuccessDetails.length + ' Updates! (Could be multiple fields per product)');
                setSubmitBtnDisabled(false);
                setShowFailedUpdateMessage(false);
                setShowNoChangeUpdateMessage(false);
                setShowSuccessfulUpdateMessage(true);

            }

        });
    }

    function SubmitChanges() {

        setLoadingBarDisabled(false);

        var formData = new FormData();
        formData.append('file', submittedFile);

        setSubmitBtnDisabled(true);

        ibcEnterpriseApi.bulkUpdateProductsFromFile(formData, updateProductMetadataChk, true).then(result => {
            setLoadingBarDisabled(true);
            const response = result.data[0];
            setNumberOfFailedUpdates(response.FailureDetails.length);
            setNumberOfSuccessUpdates(response.SuccessDetails.length);
            setNumberOfNoChangeUpdates(response.NoChangeDetails.length);
            setNumberOfNewProducts(response.NewProductDetails.length);

            setFailureUpdatesRows(response.FailureDetails);
            setSuccessUpdatesRows(response.SuccessDetails);
            setSuccessUpdatesRows(response.SuccessDetails);
            setNewProductsRows(response.NewProductDetails);

            if (response.FailureDetails.length == 0 && response.SuccessDetails.length == 0) {
                setFileSubmissionResultMessage('No Updates Required');
                setShowFailedUpdateMessage(false);
                setShowSuccessfulUpdateMessage(false);
                setShowNoChangeUpdateMessage(true);
            }
            else if (response.FailureDetails.length > 0) {
                setFileSubmissionResultMessage('No Updates Occurred. Fix all problems and resubmit file');
                setShowSuccessfulUpdateMessage(false);
                setShowNoChangeUpdateMessage(false);
                setShowFailedUpdateMessage(true);
            }
            else if (response.SuccessDetails.length > 0) {
                setFileSubmissionResultMessage('Successfully Made ' + response.SuccessDetails.length + ' Updates! (Could be multiple fields per product)');
                setShowFailedUpdateMessage(false);
                setShowNoChangeUpdateMessage(false);
                setShowSuccessfulUpdateMessage(true);

            }

            setSubmitBtnDisabled(false);

        });
    }

    function toggleAutoCreateMetaDataChk() {
        setUpdateProductMetadataChk(!updateProductMetadataChk);
    }

    function downloadVaultProductsSheet() {
        ibcEnterpriseApi.bulkUpdateProductDownloadVaultItemSheet();
    }

    let acceptedFiles = [];

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    var acceptedFileTypes = ['.csv', '.xls', '.xlsx'];

    const failureTableColumns = [

        { field: 'UPC', headerName: 'UPC', width: 150 },
        { field: 'ProductDescription', headerName: 'Description', width: 300 },
        { field: 'FailureType', headerName: 'Failure Type', width: 100 },
        { field: 'FailureReason', headerName: 'Failure Reason', width: 500 },
    ];



    const successTableColumns = [

        { field: 'UPC', headerName: 'UPC', width: 150 },
        { field: 'ProductDescription', headerName: 'Description', width: 300 },
        { field: 'ChangedFieldName', headerName: 'Field', width: 200 },
        { field: 'ChangedFieldOldValue', headerName: 'Old Value', width: 300 },
        { field: 'ChangedFieldNewValue', headerName: 'New Value', width: 300 },

    ];

    const newProductsTableColumns = [

        { field: 'UPC', headerName: 'UPC', width: 150 },
        { field: 'ProductDescription', headerName: 'Description', width: 300 },

    ];

    const gridHeader = () => {
        return (
            <GridHeader
                cardTitle="Update Failures"
                cardSubtitle="View the products that could not be updated due to an error"
            />
        )

    }

    function generateRandomId() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">



                    {/*<LoadingOverlay active={isLoading} spinner text='Updating Products...' />*/}

                    <Breadcrumbs title={'Batch Update Products'} breadcrumbItem={'Batch Update Products'} />
                    <Grid container justifyContent="flex-end">
                        <Button variant="contained"
                            color="info"
                            style={{}}
                            onClick={downloadVaultProductsSheet}
                        >Download Vault Products <Download /></Button>
                    </Grid>
                  
                    <Row className="justify-content-md-center" >
                        <Col className="col-6" >

                            <Dropzone onDrop={onDrop} accept={acceptedFileTypes} acceptedFiles={acceptedFiles}>
                                {({ getRootProps, getInputProps }) => (
                                    <section className="container">
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <Row className="justify-content-md-center" style={{ marginTop: "25px" }}>
                                                <h6>Drop a product updater template file</h6>
                                            </Row>
                                            <Row className="justify-content-md-center" style={{ marginTop: "25px" }}>
                                                <img src={DragNDropIcon} width='50px' height='50px' alt="DragNDrop" />
                                            </Row>
                                        </div>
                                        <aside>
                                            <h4>{files}</h4>
                                        </aside>
                                    </section>
                                )}
                            </Dropzone>
                           
                            <FormControlLabel
                                control=
                                {<Checkbox
                                    checked={updateProductMetadataChk}
                                    onChange={toggleAutoCreateMetaDataChk}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                                label="Auto Create Missing Metadata(eg brand, category etc)" />
                        </Col>
                    </Row>
                    <Row>
                        <Grid spacing={1} container>
                            <Grid xs item>
                                <LinearProgress hidden={loadingBarDisabled } title="test" />
                            </Grid>
                          
                        </Grid>
                    </Row>
                    {
                        showSuccessfulUpdateMessage ?
                            <Row>
                                <Col>
                                    <Alert icon={<Check fontSize="inherit" />} severity="success" hid >
                                        <h5>{fileSubmissionResultMessage}</h5>
                                    </Alert>
                                </Col>
                            </Row> : null}

                    {showFailedUpdateMessage ? <Row  >
                        <Col>
                            <Alert icon={<Close fontSize="inherit" />} severity="error" hid >
                                <h5>{fileSubmissionResultMessage}</h5>
                            </Alert>
                        </Col>
                    </Row> : null}
                    {showNoChangeUpdateMessage ? <Row  >
                        <Col>
                            <Alert icon={<Info fontSize="inherit" />} severity="info" hid >
                                <h5>{fileSubmissionResultMessage}</h5>
                            </Alert>
                        </Col>
                    </Row> : null}
                    <Row className="justify-content-md-center" >
                        <Col className="col-12" >
                                <Button variant="contained"
                                    color="success"
                                    style={{ width: '100%' }}
                                    fullWidth
                                    onClick={SubmitChanges}
                                    disabled={submitBtnDisabled}
                                >Submit Changes <Upload /></Button>
                        </Col>
                    </Row>
                    <Card  >
                        <CardBody>
                            <Row>
                                <Col>
                                    <h6>File Submission Results:</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table>
                                        <tr>Products Eligible For Updates: {numberOfSuccessUpdates}</tr>
                                        <tr><b style={{ color: 'red' }}>Products With failures: </b> {numberOfFailedUpdates}</tr>
                                        <tr>New Products: {numberOfNewProducts}</tr>
                                        <tr>Products With No Change: {numberOfNoChangeUpdates}</tr>
                                    </table>
                                </Col>
                            </Row>
                            

                        </CardBody>
                    </Card>
                            <Card >
                                <CardBody>
                                    <GridHeader cardTitle="Successful/Eligble Updates" cardSubtitle="These are the successful updates OR items eligble for updates" />
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }}>
                                            <DataGrid
                                                rows={successUpdatesRows}
                                                columns={successTableColumns}
                                                pagination
                                                getRowId={(row) => generateRandomId()}
                                                //page={pageNumber}
                                                pageSize={100}
                                            //onPageSizeChange={(e) => setPageSize(e.pageSize)}
                                            //rowsPerPageOptions={[10, 50, 100, 500, rowData.rowCount < 500 ? 1000 : rowData.rowCount]}
                                            //checkboxSelection
                                            //rowCount={rowData.rowCount}
                                            //onPageChange={handlePageChange}
                                            //loading={loading}
                                            //selectionModal={selectionModal}
                                            //onSelectionChange={onSelectionChange}
                                            //columnVisibilityModel={columnVisibilityModel}
                                            //onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                    <Row style={{ marginTop: "28px" }}>
                        <Col className="col-12">
                            <Card  >
                                <CardBody>
                                    <GridHeader cardTitle="Failures" cardSubtitle="If the submitted file contains errors, they will be displayed below. You must correct all issues before updates will occur." />
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }}>
                                            <DataGrid
                                                rows={failureUpdatesRows}
                                                columns={failureTableColumns}
                                                pagination
                                                getRowId={(row) => generateRandomId()}
                                            //page={pageNumber}
                                            //pageSize={pageSize}
                                            //onPageSizeChange={(e) => setPageSize(e.pageSize)}
                                            //rowsPerPageOptions={[10, 50, 100, 500, rowData.rowCount < 500 ? 1000 : rowData.rowCount]}
                                            //checkboxSelection
                                            //rowCount={rowData.rowCount}
                                            //onPageChange={handlePageChange}
                                            //loading={loading}
                                            //selectionModal={selectionModal}
                                            //onSelectionChange={onSelectionChange}
                                            //columnVisibilityModel={columnVisibilityModel}
                                            //onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                   
                    <Row style={{ marginTop: "28px" }}>
                        <Col className="col-12">
                            <Card  >
                                <CardBody>
                                    <GridHeader cardTitle="New Products" cardSubtitle="These are products that will be added" />
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }}>
                                            <DataGrid
                                                rows={newProductsRows}
                                                columns={newProductsTableColumns}
                                                pagination
                                                getRowId={(row) => generateRandomId()}
                                            //page={pageNumber}
                                            //pageSize={pageSize}
                                            //onPageSizeChange={(e) => setPageSize(e.pageSize)}
                                            //rowsPerPageOptions={[10, 50, 100, 500, rowData.rowCount < 500 ? 1000 : rowData.rowCount]}
                                            //checkboxSelection
                                            //rowCount={rowData.rowCount}
                                            //onPageChange={handlePageChange}
                                            //loading={loading}
                                            //selectionModal={selectionModal}
                                            //onSelectionChange={onSelectionChange}
                                            //columnVisibilityModel={columnVisibilityModel}
                                            //onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )
}

export default ProductBulkUpdate;