import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { List } from "react-virtualized";
import '../../styles/ProductCreateForm.css';
import FieldError from "./Product/FieldError";
import { Controller } from 'react-hook-form';
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
const useStyles = value =>
  makeStyles(theme => ({
    root: {
      "& .Mui-error": {
        color: "#DC4C64"
      }
    }
  }));

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={250}
          width={350}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(props) => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});


export default function VirtualizedAutocomplete(props) {
  const { className, options, name, rules, control, defaultValue, errorMessage } = props;
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = React.useState("");

  const handleInputChange = (event, newValue) => {
    setSelectedItem(newValue); 
  };

  return <>
    <div className={className}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({field: {ref, ...field}, fieldState: {error}}) => (
          <Autocomplete
            {...field}
            id="virtualize-demo"
            disableListWrap
            ListboxComponent={ListboxComponent}
            inputValue={selectedItem}
            onInputChange={handleInputChange}
            onChange={(event, value) => field.onChange(value || null)}
            options={options}
            getOptionLabel={(option) => option.Name}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  className={classes.root}
                  variant="outlined"
                  size="small"
                  label={name}
                  fullWidth
                  error={!!error}
                  inputRef={ref}
                />
                {!!error && <FieldError errorMessage={errorMessage} />}
              </>
            )}
          />
        )}
      />
      
      
    </div>
  </>
}