import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import PromptAlert from "../../Common/PromptAlert";

//i18n
import { withNamespaces } from 'react-i18next';

const NotificationDropdown = (props) => {

  // Global state
  const {
    cart,
    alert,
  } = useStoreState(state => state);

  // Global state modifiers
  const {
    fetchCartState,
    checkoutCart,
    toggleAlert
  } = useStoreActions(actions => actions);

  // Local state
  const [menu, setMenu] = useState(false);
  const history = useHistory();


  // Effects
  useEffect(() => {
    if (cart.items.length === 0) fetchCartState();
  }, [])


  // Local functions
  const toggle = () => {
    setMenu(!menu);
  }

  const handleCheckout = () => {
    toggleAlert(alert.checkoutCart)
    toggle();
  }

  const formatCartSize = (num) => {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(1).replace(rx, "$1") + si[i].symbol;
  }

  return (
    <React.Fragment>
      <PromptAlert alert={alert.checkoutCart} checkoutCart={checkoutCart} history={history} />
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="dropdown d-inline-block"
        tag="li"
        style={{ width: "70px" }}
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button" id="page-header-notifications-dropdown" style={{ width: "100%" }}>

          {cart.items && cart.items.length === 0 ? <i className="bx bx-cart"></i> : <i className="bx bx-cart bx-tada"></i>}
          <span className="badge badge-danger badge-pill" style={{
            position: 'absolute', left: '75%', top: '28%',
            transform: 'translate(-50%, -50%)'
          }} >{cart.items && cart.items.length !== 0 ? formatCartSize(cart.items.length) : null}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t('Product Order')} </h6>
              </Col>
              <div className="col-auto">
                <Link to="/orders/cart" className="small">Preview Order</Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {cart.items.length > 0 ?
              <Link to={'/products/detail/' + cart.items[0].id} className="text-reset notification-item">
                <div className="media">
                  <img src={cart.items[0].image} style={{ height: "50px", width: "50px", objectFit: "contain" }} alt="user-pic" />
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">{cart.items[0].name}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-0"><i className="mdi mdi-clock-outline"></i>{props.t('1 minute ago')} </p>
                    </div>
                  </div>
                </div>
              </Link>
              : null}
            {cart.items.length > 1 ?
              <Link to={'/products/detail/' + cart.items[1].id} className="text-reset notification-item">
                <div className="media">
                  <img src={cart.items[1].image} style={{ height: "50px", width: "50px", objectFit: "contain" }} alt="user-pic" />
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">{cart.items[1].name}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-0"><i className="mdi mdi-clock-outline"></i>{props.t('1 minute ago')} </p>
                    </div>
                  </div>
                </div>
              </Link>
              : null}
            {cart.items.length > 2 ?
              <Link to={'/products/detail/' + cart.items[2].id} className="text-reset notification-item">
                <div className="media">
                  <img src={cart.items[2].image} style={{ height: "50px", width: "50px", objectFit: "contain" }} alt="user-pic" />
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">{cart.items[2].name}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-0"><i className="mdi mdi-clock-outline"></i>{props.t('1 minute ago')} </p>
                    </div>
                  </div>
                </div>
              </Link>
              : null}
            {cart.items.length > 3 ?
              <Link to={'/products/detail/' + cart.items[3].id} className="text-reset notification-item">
                <div className="media">
                  <img src={cart.items[3].image} style={{ height: "50px", width: "50px", objectFit: "contain" }} alt="user-pic" />
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">{cart.items[3].name}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-0"><i className="mdi mdi-clock-outline"></i>{props.t('1 minute ago')} </p>
                    </div>
                  </div>
                </div>
              </Link>
              : null}
            {cart.items.length > 4 ?
              <Link to={'/products/detail/' + cart.items[4].id} className="text-reset notification-item">
                <div className="media">
                  <img src={cart.items[4].image} style={{ height: "50px", width: "50px", objectFit: "contain" }} alt="user-pic" />
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">{cart.items[4].name}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-0"><i className="mdi mdi-clock-outline"></i>{props.t('1 minute ago')} </p>
                    </div>
                  </div>
                </div>
              </Link>
              : null}
          </SimpleBar>
          <div className="p-2 border-top">
            <Button className="btn btn-sm btn-success btn-link font-size-14 btn-block text-center" onClick={handleCheckout} disabled={cart.items.length == 0}>Checkout</Button>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}
export default withNamespaces()(NotificationDropdown);
