import authentication from '@kdpw/msal-b2c-react'
import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_IBC_ENTERPRISE_API_BASE_URL + '/api'
});

const unwrapResponse = (responseObj) => {
    return responseObj.OperationResult;
}

const ibcEnterpriseApi = {

    getProductById: (productId) =>
        instance({
            'method': 'GET',

            'url': '/product/getbyid',
            'params': {
                'productId': productId,
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj?.Data[0];
            }],
        }),
    getProductByUPCA: (upca) =>
        instance({
            'method': 'GET',
            'url': '/product/getbyupca',
            'params': {
                'UPCA': upca,
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj?.Data[0];
            }],
        }),
    getProductByIdIncludeRelated: (productId) =>
        instance({
            'method': 'GET',
            'url': '/product/getByIdIncludeRelated',
            'params': {
                'productId': productId,
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj?.Data[0];
            }],
        }),
    getAllProductsByUpcaList: (upcList) =>
        instance({
            'method': 'POST',
            'url': '/product/getAllProductsByUpcaList',
            'data': {
                'upcList': upcList
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj?.Data[0];
            }],
        }),
    getAllProductsByUpcaListIncludeRelated: (upcList) =>
        instance({
            'method': 'POST',
            'url': '/product/getAllProductsByUpcaListIncludeRelated',
            'data': {
                'upcList': upcList
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                if (!responseObj) return;
                return responseObj?.Data[0];
            }],
        }),
    getAllProductBrands: () =>
        instance({
            'method': 'GET',
            'url': '/productbrand/getall',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getAllProductFamilies: () =>
        instance({
            'method': 'GET',
            'url': '/productfamily/getall',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getAllIbcProducts: () =>
        instance({
            'method': 'GET',
            'url': '/ibcproduct/getall',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data ? responseObj.Data : [{ Id: 0, SalesforceId: 0, Name: "null" }];
            }],
        }),
    getAllProductCategories: () =>
        instance({
            'method': 'GET',
            'url': '/productcategory/getall',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getAllProductSubCategories: () =>
        instance({
            'method': 'GET',
            'url': '/productsubcategory/getall',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    searchProducts: (searchValue, pageNumber, pageSize, filterOptionsDTO) =>
        instance({
            'method': 'POST',
            'url': '/product/search',
            'params': {
                'searchValue': searchValue,
                'pageNumber': pageNumber,
                'pageSize': pageSize
            },
            'data': filterOptionsDTO,
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    updateProduct: (productDTO) =>
        instance({
            'method': 'PUT',
            'url': '/product',
            'data': {
                'ProductDTO': productDTO
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
        }),
    createProduct: (productDTO) =>
        instance({
            'method': 'POST',
            'url': '/product/create',
            'data': {
                'ProductDTO': productDTO
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    deleteProduct: (productId) =>
        instance({
            'method': 'DELETE',
            'url': '/product',
            'params': {
                'productId': productId
            },
            'headers': {
                'content-type': 'application/json'
            },
        }),

    addImages: (formData) =>
        instance({
            method: 'post',
            url: '/productImage',
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj?.Data[0];
            }],
        }),

    deleteProductImage: (imageId) =>
        instance({
            'method': 'DELETE',
            'url': '/productImage',
            'params': {
                'imageId': imageId
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj?.Data[0];
            }],
        }),
    createOrder: (orderDTO) =>
        instance({
            'method': 'POST',
            'url': '/order/create',
            'data': {
                'OrderDTO': orderDTO
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    updateOrder: (orderDTO) =>
        instance({
            'method': 'PUT',
            'url': '/order/update',
            'data': {
                'OrderDTO': orderDTO
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    deleteMultipleOrderItems: (orderItemIds) =>
        instance({
            'method': 'DELETE',
            'url': '/orderitem/deletemultiple',
            'data': {
                'OrderItemIds': orderItemIds
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    createMultipleOrderItems: (orderItems) =>
        instance({
            'method': 'POST',
            'url': '/orderitem/createmultiple',
            'data': {
                'orderItems': orderItems
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    createOrderItem: (orderItem) =>
        instance({
            'method': 'POST',
            'url': '/ordercart/create',
            'data': {
                'orderItem': orderItem
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getLatestOrderId: () =>
        instance({
            'method': 'GET',
            'url': '/order/getlatestid',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getTotalOrderCount: () =>
        instance({
            'method': 'GET',
            'url': '/order/gettotalordercount',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getTotalProductCount: () =>
        instance({
            'method': 'GET',
            'url': '/product/gettotalproductcount',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getAllCheckedOutOrders: () =>
        instance({
            'method': 'GET',
            'url': '/order/getallcheckedoutorders',
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    getOrderByIdIncludeItems: (orderId) =>
        instance({
            'method': 'GET',
            'url': '/order/getbyidincludeitems/',
            'params': {
                'orderId': orderId,
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    downloadOrderById: (orderId) =>
        instance({
            'method': 'GET',
            'url': '/order/downloadOrderById/',
            'params': {
                'orderId': orderId,
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    createMultipleProducts: (csvRows) =>
        instance({
            'method': 'POST',
            'url': '/product/createMultipleProducts',
            'data': {
                'csvRows': csvRows
            },
            'headers': {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    bulkUpdateProductsFromFile: (file, updateProductMetadataChk, submitChanges) =>

        instance({
            'method': 'POST',
            'url': '/product/bulkUpdateFromFile?createMissingMetaData=' + updateProductMetadataChk + '&submitChanges=' + submitChanges,
            'data': file,
            'headers': {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                const responseObj = unwrapResponse(JSON.parse(data));
                return responseObj.Data;
            }],
        }),
    bulkUpdateProductDownloadVaultItemSheet: () =>

        instance({
            'method': 'GET',
            'url': '/product/Product/Product Updater Template - Vault Items',
            responseType: "blob",
            'headers': {
                'content-type': 'application/vnd.ms-excel',
                'Authorization': 'Bearer ' + authentication.getAccessToken()
            },
            transformResponse: [function (data) {
                // We got the raw excel sheet data back from the server, now we will transform it
                // and do the standard "create an invisiable button and click it" trick to get the browser
                // to download the file as we intend
                var blob = new Blob([data], {
                    type: 'application/vnd.ms-excel',
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `Product Bulk Updater - Vault Items.xlsx`;
                link.click();
            }],
        }),
}

export { ibcEnterpriseApi }