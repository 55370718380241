import { action, thunk, computed } from 'easy-peasy';
import { request } from 'http';
import { ibcEnterpriseApi } from '../apis/ibcEnterpriseApi';

export default {

    // State //
    numberOfFailedUpdates: 0,

    product: {
        Id: null,
        UPCA: null,
        UPC11: null,
        UPC10: null,
        UPCE: null,
        GTIN8: null,
        GTIN13: null,
        GTIN14: null,
        ShortDescription: null,
        FullDescription: null,
        ProductCategory: null,
        ProductCategoryId: null,
        ProductSubCategory: null,
        ProductSubCategoryId: null,
        ProductFamily: null,
        ProductFamilyId: null,
        ProductBrand: null,
        ProductBrandId: null,
        InnerProduct: null,
        InnerProductId: null,
        HeightInches: null,
        WidthInches: null,
        DepthInches: null,
        WeightPounds: null,
        CaseQuantity: null,
        PackType: null,
        PackSize: null,
        VendorProductName: null,
        MaterialNumber: null,
        IbcProductId: null,
        IbcProduct: null,
        IsColdVault: null,
        IsAlcohol: null,
        ProductType: null,
        VerificationStatus: null,
        CreatedOn: null,
        CreatedBy: null,
        LastModifiedOn: null,
        LastModifiedBy: null
    },
    user: {
        username: null,
        firstName: null,
        lastName: null
    },
    cart: {
        items: []
    },
    order: {
        latestId: null,
        items: [],
        status: null,
        name: "Untitled",
        createdBy: null,
        createdOn: null,
        downloadedOn: null,
        size: computed(state => (state.items.length * 40.13 + 4.5) / 100.0),
        itemCount: computed(state => state.items.length),
        isCartEmpty: computed(state => state.items.length === 0)
    },
    orderHistory: {
        orders: [

        ]
    },
    upcSearchList: [],
    rowData: {
        rowCount: 0,
        rows: []
    },
    shouldAutofillProductData: false,
    shouldResetProduct: true,
    shouldSaveProduct: false,
    prevUPCA: null,
    brand: {
        Id: null,
        Name: null
    },
    category: {
        Id: null,
        Name: null
    },
    subcategory: {
        Id: null,
        Name: null
    },
    productFamily: {
        Id: null,
        Name: null
    },
    ibcProduct: {
        Id: null,
        SalesforceId: null,
        Name: null
    },
    images: [{
        Id: null,
        ProductId: null,
        Url: null
    }],
    galleryImages: [{
        original: null,
        thumbnail: null,
        description: null,
        render: null
    }],
    galleryItem: {
        original: null,
        thumbnail: null,
        description: null,
        render: null
    },
    dropZoneImages: [],
    csvDropFiles: [],
    brands: [],
    categories: [],
    subcategories: [],
    productFamilies: [],
    ibcProducts: [],
    alert: {
        deleteProduct: {
            id: 0,
            display: false
        },
        deleteImage: {
            id: 1,
            display: false
        },
        cannotUpdateUPCA: {
            id: 2,
            display: false
        },
        duplicateUPCA: {
            id: 3,
            display: false
        },
        invalidUPCA: {
            id: 4,
            display: false
        },
        checkoutCart: {
            id: 5,
            display: false,
            success: false
        },
        responseResults: {
            id: 6,
            display: false
        },
        lookupResults: {
            id: 7,
            display: false
        },
    },
    dynamic: {
        title: null,
        description: null,
    },
    spinner: {
        loadingImages: false,
    }, // End of State //


    //    Thunks    //
    fetchCartState: thunk(async actions => {
        const latestOrderId = await actions.fetchLatestOrderId();
        if (latestOrderId) actions.fetchOrderByIdIncludeItems({ Id: latestOrderId })
    }),
    fetchOrderHistory: thunk(async actions => {
        await ibcEnterpriseApi.getAllCheckedOutOrders()
            .then(result => {
                const orderHistory = result.data[0];
                actions.setOrderHistory({ orderHistory: orderHistory });
            })
    }),
    fetchInitialState: thunk(async (actions, payload) => {
        const { id, brands, categories, subcategories, productFamilies, ibcProducts } = payload;
        if (id) actions.fetchProduct(id);
        if (!brands || brands.length === 0) actions.fetchBrands();  // Fetch brands if not already fetched
        if (!categories || categories.length === 0) actions.fetchCategories(); // Fetch categories if not already fetched
        if (!subcategories || subcategories.length === 0) actions.fetchSubCategories(); // Fetch subcategories if not already fetched
        if (!productFamilies || productFamilies.length === 0) actions.fetchProductFamilies(); // Fetch productFamilies if not already fetched
        if (!ibcProducts || ibcProducts.length === 0) actions.fetchIbcProducts(); // Fetch ibcProducts if not already fetched
    }),
    fetchProduct: thunk(async (actions, id) => {
        ibcEnterpriseApi.getProductByIdIncludeRelated(id)
            .then(result => {
                if (result) { // Success if result is not null
                    const data = result.data;
                    actions.setProduct(data);
                    actions.setBrand(data.ProductBrand);
                    actions.setCategory(data.ProductCategory);
                    actions.setSubcategory(data.ProductSubCategory);
                    actions.setProductFamily(data.ProductFamily);
                    actions.setIbcProduct(data.IbcProduct);
                    actions.setImages(data.Images);
                }
            });
    }),
    fetchBrands: thunk(async (actions) => {
        ibcEnterpriseApi.getAllProductBrands()
            .then(result => {
                const brands = result.data;
                actions.setBrands(brands);
            });

    }),
    fetchCategories: thunk(async (actions) => {
        ibcEnterpriseApi.getAllProductCategories()
            .then(result => {
                const categories = result.data;
                actions.setCategories(categories);
            });
    }),
    fetchSubCategories: thunk(async (actions) => {
        ibcEnterpriseApi.getAllProductSubCategories()
            .then(result => {
                const subcategories = result.data;
                actions.setSubcategories(subcategories);
            });
    }),
    fetchProductFamilies: thunk(async (actions) => {
        ibcEnterpriseApi.getAllProductFamilies()
            .then(result => {
                const productFamilies = result.data;
                actions.setProductFamilies(productFamilies);
            });
    }),
    fetchIbcProducts: thunk(async (actions) => {
        ibcEnterpriseApi.getAllIbcProducts()
            .then(result => {
                const ibcProducts = result.data;
                actions.setIbcProducts(ibcProducts);
            });

    }),
    saveProduct: thunk(async (actions, payload) => {
        actions.toggleLoadingImageSpinner(); // Start uploading images
        actions.postProduct(payload.product);
        actions.uploadImages(payload);
    }),
    editProductCommand: thunk(async (actions, payload) => {
        actions.postProduct(payload.product);
    }),

    createProduct: thunk(async (actions, payload) => {
        var product = payload.product;
        //delete product.Id; // delete Id to match the DTO 
        actions.toggleLoadingImageSpinner(); // Start uploading images
        ibcEnterpriseApi.createProduct(product)
            .then(result => {
                const data = result.data[0];
                payload.Id = data.Id;
                payload.UPCA = data.UPCA;
                actions.uploadImages(payload);
            });

    }),
    uploadImages: thunk(async (actions, payload) => {
        let uploads = payload.dropZoneImages.map(image => {
            var formData = new FormData();
            formData.append("image", image);
            formData.append("productId", payload.Id);
            formData.append("productUPCA", payload.UPCA);
            formData.append("creator", "Admin Portal");
            return actions.postImage(formData);
        });

        Promise.all(uploads)
            .then(() => {
                actions.toggleLoadingImageSpinner(); // Images finished uploading 
                actions.setDropZoneImages([]);
                payload.props.history.push({
                    pathname: '/products/detail/' + payload.Id
                });
            });
    }),
    postProduct: thunk(async (actions, product) => {
        ibcEnterpriseApi.updateProduct(product);
    }),
    postImage: thunk(async (actions, formData) => {
        return ibcEnterpriseApi.addImages(formData)
            .then(result => {
                const img = result.data;
                actions.concatImage(img);
            });
    }),
    checkoutCart: thunk(async (actions, payload) => {
        await actions.updateOrder(payload)

        actions.addOrderToHistory();
        actions.replaceCartWith({ OrderItems: [] });
        payload.history.push({
            pathname: '/orders/history'
        })
    }),
    createOrderItems: thunk(async (actions, payload) => {
        const { products, isCartEmpty, status, orderItems } = payload;
        var latestOrderId = payload.latestOrderId;

        if (isCartEmpty && status !== "Active") await ibcEnterpriseApi.createOrder({}).then(response => {
            if (response.data.length !== 0) {
                var respData = response.data;
                var data = respData[0];
                latestOrderId = data.Id;
            }
        });

        var newIdlessOrderItems = products.map(product => {
            return { OrderId: latestOrderId, ProductId: product.id }
        });

        if (newIdlessOrderItems.length > 0) await ibcEnterpriseApi.createMultipleOrderItems(newIdlessOrderItems)
            .then(response => {
                var respData = response.data;
                var newOrderItems = respData[0];
                newOrderItems = newOrderItems.concat(orderItems); // combine current orderitems with the new order items
                var newCartItems = newOrderItems.map(item => {
                    if (item.Product == null) {
                        item.Product = products.find(product => product.id === item.ProductId);
                    }
                    return item;
                })
                actions.setOrder({ Id: latestOrderId, OrderItems: newOrderItems });
                actions.setCart({ OrderItems: newCartItems });
            });
    }),
    updateOrder: thunk(async (actions, payload) => {
        const orderDTO = {
            Id: payload.order.latestId,
            Name: payload.order.name,
            Status: payload.order.status,
            ItemCount: payload.order.itemCount,
            Size: payload.order.size
        }
        if (payload.order.latestId) await ibcEnterpriseApi.updateOrder(orderDTO)
            .then(result => {
                if (result.data.length > 0) actions.setOrder({ OrderItems: [] })
            });
    }),
    fetchLatestOrderId: thunk(async (actions) => {
        var latestId = null;
        await ibcEnterpriseApi.getLatestOrderId()
            .then(result => {
                latestId = result.data[0];
                actions.setLatestOrderId(latestId);
            });
        return latestId;
    }),
    fetchOrderByIdIncludeItems: thunk(async (actions, payload) => {
        ibcEnterpriseApi.getOrderByIdIncludeItems(payload.Id)
            .then(result => {
                const order = result.data[0];
                const orderItems = order.OrderItems;
                const orderPayload = {
                    Id: order.Id,
                    OrderItems: orderItems
                }
                const cartPayload = {
                    OrderItems: orderItems
                }
                actions.setOrder(orderPayload);
                actions.setCart(cartPayload);
            });
    }),
    downloadOrderById: thunk(async (actions, payload) => {
        const { orderId } = payload;
        ibcEnterpriseApi.downloadOrderById(orderId)
            .then((result) => {
                if (result.data.length > 0)
                    window.location = result.data[0].Uri;
            });

    }),
    fetchProductsByUpcList: thunk(async (actions, upcList) => {
        ibcEnterpriseApi.getAllProductsByUpcaListIncludeRelated(upcList)
            .then(result => {

                actions.setAlertMessages([`${result.data.TotalFound} out of ${result.data.TotalRequested} products found`, ""]);
                actions.toggleResultAlert();

                var searchResultData = [];
                var foundProducts = result.data.FoundProducts;
                var notFoundProducts = result.data.NotFoundProducts;
                var totalFound = result.data.TotalFound;
                var totalNotFound = result.data.TotalNotFound;
                var tempId = -1;

                notFoundProducts.map(product => product.Id = tempId--);

                if (totalFound > 0 || totalNotFound > 0) {

                    searchResultData = [...foundProducts, ...notFoundProducts];


                    var rows = [];
                    searchResultData.map(product => {
                        let newRow = {};

                        newRow.id = product.Id;
                        newRow.upca = product.UPCA;
                        newRow.name = product.ShortDescription;
                        newRow.isColdVault = product.IsColdVault;
                        newRow.subCategory = (product.ProductSubCategory) ? product.ProductSubCategory.Name : "No SubCategory";
                        newRow.ibcProduct = (product.IbcProduct) ? product.IbcProduct.Name : "No IbcProduct";
                        newRow.family = (product.ProductFamily) ? product.ProductFamily.Name : "No Family";
                        newRow.match = (product.ShortDescription) ? "Found" : "Not Found";
                        newRow.brand = (product.ProductBrand) ? product.ProductBrand.Name : "No Brand";
                        newRow.category = (product.ProductCategory) ? product.ProductCategory.Name : "No Category";
                        newRow.description = product.FullDescription;
                        if (product.CreatedOn != null) newRow.createdOn = product.CreatedOn.substring(0, 10);
                        if (product.CreatedBy != null) newRow.createdBy = product.CreatedBy.substring(0, 10);
                        if (product.LastModifiedBy != null) newRow.lastModifiedBy = product.LastModifiedBy.substring(0, 10);
                        if (product.LastModifiedOn != null) {
                            var modifiedTime = new Date(product.LastModifiedOn);
                            modifiedTime.setHours(modifiedTime.getHours() - 5);
                            newRow.lastModifiedOn = modifiedTime.toLocaleDateString() + " " + modifiedTime.toLocaleTimeString();
                        }
                        if (product.Images && product.Images.length > 0) {
                            let imageUrl = product.Images[0].Url;
                            newRow.image = imageUrl;
                        }
                        else newRow.image = "No Image";

                        //newRow.clickEvent = (rowData) => ()

                        rows.push(newRow);
                    })
                    actions.setRowData({
                        rowCount: rows.length,
                        rows: rows
                    })
                }
            });
    }),
    // End of Thunks //


    // Actions //
    addOrderToHistory: action(state => {
        state.orderHistory.orders = [{
            id: state.order.latestId,
            name: state.order.name,
            itemCount: state.order.itemCount,
            size: state.order.size,
            createdOn: state.order.createdOn
        }].concat(state.orderHistory.orders);
    }),
    setOrderHistory: action((state, payload) => {
        state.orderHistory.orders = payload.orderHistory.map(order => {
            return {
                id: order.Id,
                name: order.Name,
                itemCount: order.ItemCount,
                size: order.Size,
                createdOn: order.CreatedOn,
                downloaded: order.LastDownloaded,
                save: order.Id
            }
        })
    }),
    addToCart: action((state, products) => {

        // Create Order Item <-> Cart item for each productid not in Order items

        // const removedDuplicateProducts = products.filter(product => !state.order.items.includes(product))
        state.cart.items = state.cart.items.concat(products);
    }),
    replaceCartWith: action((state, payload) => {
        state.cart.items.length = payload.OrderItems.length;
        state.cart.items = payload.OrderItems;
        state.order.items = payload.OrderItems;
    }),
    deleteProductImage: action((state) => {
        ibcEnterpriseApi.deleteProductImage(parseInt(state.galleryItem.description)); // Thunk-like action
        state.images = state.images.filter(image => image.Url !== state.galleryItem.original); // Might be unnecessary
        state.galleryImages = state.galleryImages.filter(image => image.Url !== state.galleryItem.original);
    }),
    concatImage: action((state, image) => {
        state.images = state.images.concat([image]);
    }),
    setProduct: action((state, data) => {
        // state.prevUPCA = (state.product.UPCA) ? data.UPCA : state.product.UPCA;
        state.product.Id = data.Id;
        state.product.UPCA = data.UPCA;
        state.product.UPC10 = data.UPC10;
        state.product.UPC11 = data.UPC11;
        state.product.UPCE = data.UPCE;
        state.product.GTIN8 = data.GTIN8;
        state.product.GTIN13 = data.GTIN13;
        state.product.GTIN14 = data.GTIN14;
        state.product.ShortDescription = data.ShortDescription;
        state.product.FullDescription = data.FullDescription;
        state.product.ProductBrandId = data.ProductBrandId;
        state.product.ProductCategoryId = data.ProductCategoryId;
        state.product.ProductSubCategoryId = data.ProductSubCategoryId;
        state.product.ProductFamilyId = data.ProductFamilyId;
        state.product.InnerProductId = data.InnerProductId;
        state.product.HeightInches = data.HeightInches;
        state.product.WidthInches = data.WidthInches;
        state.product.DepthInches = data.DepthInches;
        state.product.WeightPounds = data.WeightPounds;
        state.product.CaseQuantity = data.CaseQuantity;
        state.product.PackType = data.PackType;
        state.product.PackSize = data.PackSize;
        state.product.VendorProductName = data.VendorProductName;
        state.product.MaterialNumber = data.MaterialNumber;
        state.product.IbcProductId = data.IbcProductId;
        state.product.IsColdVault = data.IsColdVault;
        state.product.IsAlcohol = data.IsAlcohol;
        state.product.ProductType = data.ProductType;
        state.product.CreatedOn = data.CreatedOn;
        state.product.CreatedBy = data.CreatedBy;
        state.product.LastModifiedOn = data.LastModifiedOn;
        state.product.LastModifiedBy = data.LastModifiedBy;
    }),
    setProductFromSearch: action((state, data) => {
        state.product.Id = data.id;
        state.product.UPCA = data.upca;
        state.product.ShortDescription = data.name;
        state.product.ProductBrand = data.brand;
        state.product.ProductBrandId = data.brandId;
        state.product.ProductCategory = data.category;
        state.product.ProductCategoryId = data.categoryId;
        state.product.ProductSubCategory = data.subCategory;
        state.product.ProductSubCategoryId = data.subCategoryId;
        state.product.ProductFamily = data.family;
        state.product.ProductFamilyId = data.familyId;
        state.product.IbcProduct = data.ibcProduct;
        state.product.IbcProductId = data.ibcProductId;
        state.product.IsColdVault = data.isColdVault;
        state.product.CreatedOn = data.ceatedOn;
        state.product.CreatedBy = data.createdBy;
        state.product.LastModifiedOn = data.lastModifiedOn;
        state.product.LastModifiedBy = data.lastModifiedBy;
    }),
    setProductId: action((state, id) => {
        state.product.Id = id;
    }),
    setBrands: action((state, brands) => {
        state.brands = state.brands.concat(brands);
    }),
    setCategories: action((state, categories) => {
        state.categories = state.categories.concat(categories);
    }),
    setSubcategories: action((state, subcategories) => {
        state.subcategories = state.subcategories.concat(subcategories);
    }),
    setProductFamilies: action((state, productFamilies) => {
        state.productFamilies = state.productFamilies.concat(productFamilies);
    }),
    setIbcProducts: action((state, ibcProducts) => {
        state.ibcProducts = ibcProducts;
    }),
    setImages: action((state, images) => {
        state.images = images;
    }),
    setGallery: action((state, render) => {
        state.galleryImages = state.images.map(function (image) {
            return render ? {
                original: image.Url, // Properties for ProductEdit
                thumbnail: image.Url,
                description: image.Id,
                renderItem: render
            } : {
                original: image.Url, // Properties for ProductDetail
                thumbnail: image.Url,
            }
        })
    }),
    toggleAlert: action((state, alert) => {

        Object.keys(state.alert).map(function (key) { // Maps through all alert objects
            if (state.alert[key].id === alert.id) state.alert[key].display = !state.alert[key].display; // Toggles display value for passed in alert
        })
    }),
    toggleResultAlert: action((state) => {
        state.alert.lookupResults.display = !state.alert.lookupResults.display;
    }),
    setAlertMessages: action((state, messages) => {
        state.dynamic.title = messages[0];
        state.dynamic.description = messages[1];
    }),
    setDropZoneImages: action((state, images) => {
        state.dropZoneImages = images;
    }),
    setCSVDropFiles: action((state, files) => {
        state.csvDropFiles = files;
    }),
    setBrand: action((state, brand) => {
        if (brand) {
            state.brand = brand;
            state.product.ProductBrandId = brand.Id;
        }
    }),
    setCategory: action((state, category) => {
        if (category) {
            state.category = category;
            state.product.ProductCategoryId = category.Id;
        }
        else {
            state.category.Name = null;
            state.category.Id = null;
            state.product.ProductCategoryId = null;
        }
    }),
    setSubcategory: action((state, subcategory) => {
        if (subcategory) {
            state.subcategory = subcategory;
            state.product.ProductSubCategoryId = subcategory.Id;
        }
        else {
            state.subcategory.Name = null;
            state.subcategory.Id = null;
            state.product.ProductSubCategoryId = null;
        }
    }),
    setProductFamily: action((state, productFamily) => {
        if (productFamily) {
            state.productFamily = productFamily;
            state.product.ProductFamilyId = productFamily.Id;
        }
        else {
            state.productFamily.Name = null;
            state.productFamily.Id = null;
            state.product.ProductFamilyId = null;
        }
    }),
    setIbcProduct: action((state, ibcProduct) => {
        if (ibcProduct) {
            state.ibcProduct = ibcProduct;
            state.product.IbcProductId = ibcProduct.Id;
        }
        else {
            state.ibcProduct.Name = null;
            state.ibcProduct.Id = null;
            state.ibcProduct.SalesforceId = null;
        }
    }),
    toggleLoadingImageSpinner: action((state) => {
        state.spinner.loadingImages = !state.spinner.loadingImages;
    }),
    setGalleryItem: action((state, item) => {

        state.galleryItem = item;
    }),
    deleteProductImageLocal: action((state) => {
        state.images.filter(image => image.Url !== state.galleryItem.original);
    }),
    setPrevUPCA: action((state, upca) => {
        state.prevUPCA = upca;
    }),
    revertUPCA: action((state) => {
        state.product.UPCA = state.prevUPCA;
    }),
    resetProduct: action(state => {
        state.product.Id = null;
        state.product.UPCA = null;
        state.product.UPC10 = null;
        state.product.UPC11 = null;
        state.product.UPCE = null;
        state.product.GTIN8 = null;
        state.product.GTIN13 = null;
        state.product.GTIN14 = null;
        state.product.ShortDescription = null;
        state.product.FullDescription = null;
        state.product.ProductCategoryId = null;
        state.product.ProductBrandId = null;
        state.product.InnerProductId = null;
        state.product.HeightInches = null;
        state.product.WidthInches = null;
        state.product.DepthInches = null;
        state.product.WeightPounds = null;
        state.product.CaseQuantity = null;
        state.product.PackType = null;
        state.product.PackSize = null;
        state.product.VendorProductName = null;
        state.product.MaterialNumber = null;
        state.product.IbcProductId = null;
        state.product.createdOn = null;
        state.product.LastModifiedOn = null;
        state.brand.Name = null;
        state.brand.Id = null;
        state.category.Name = null;
        state.category.Id = null;
        state.images = [{
            Id: null,
            ProductId: null,
            Url: null
        }];
        state.galleryImages = [{
            original: null,
            thumbnail: null,
            description: null,
            render: null
        }];
    }),
    toggleShouldResetProduct: action(state => {
        state.shouldResetProduct = !state.shouldResetProduct;
    }),
    setShouldAutofillProductData: action((state, shouldAutofillData) => {
        state.shouldAutofillProductData = shouldAutofillData;
    }),
    setProductUPCA: action((state, upca) => {
        state.product.UPCA = upca;
    }),
    setLatestOrderId: action((state, id) => {
        state.order.latestId = id;
    }),
    setOrder: action((state, payload) => {
        if (payload.Id) state.order.latestId = payload.Id;
        if (payload.OrderItems) state.order.items = payload.OrderItems;
        if (payload.Name) state.order.name = payload.Name;
        if (payload.Status) state.order.status = payload.Status;
        if (payload.ItemCount) state.order.itemCount = payload.ItemCount;
        if (payload.Size) state.order.size = payload.Size;
        if (payload.CreatedOn) state.order.createdOn = payload.CreatedOn;
    }),
    setCart: action((state, payload) => {
        if (payload.OrderItems) state.cart.items = payload.OrderItems.map(item => {
            var imageUrl = "No Image";
            if (typeof item.Product.Images === 'string' || item.Product.Images instanceof String) imageUrl = item.Product.Images;
            else if (item.Product.Images && item.Product.Images.length > 0) imageUrl = item.Product.Images[0].Url;
            const productId = (item.Product.Id) ? item.Product.Id : item.Product.id;
            return {
                id: productId,
                upca: item.Product.UPCA,
                name: item.Product.ShortDescription,
                description: item.Product.FullDescription,
                brand: item.Product.ProductBrand ? item.Product.ProductBrand.Name : "No Brand",
                category: item.Product.ProductCategory ? item.Product.ProductCategory.Name : "No Category",
                image: imageUrl,
                orderItemId: item.Id
            }
        });
    }),
    setUpcSearchList: action((state, { upcList }) => {
        state.upcSearchList = upcList
    }),
    setRowData: action((state, { rowCount, rows }) => {
        state.rowData.rowCount = rowCount;
        state.rowData.rows = rows;
    }),
    setAdminUser: action((state, user) => {
        const { username, firstName, lastName } = user;
        state.user.username = username;
        state.user.firstName = firstName;
        state.user.lastName = lastName;
    }),

    bulkUpdateProductsSetFailureRows: action((state, rows ) => {
        state.productBulkUpdater.failureGridData.rows = rows;
    }),

    // End of Actions // 

    // Computed //
    validUPCA: action((upca) => {
        const u = (upca) ? upca : ''; // Grab upca and handle undefined
        return ((!isNaN(u)) && u.trim().length === 12); // return false if it's not a 12 digit number
    }),
    isCartEmpty: action(state => {
        return state.order.items.length === 0;
    }),


    // CSV Drop Model
    CSVDrop: {
        // State
        dropData: [],
        validCol: ["Largest Available UPC", "Name", "IBC Product", "Brand", "Category", "Product Subcategory", "Is Cold Vault", "Is Alcohol", "Product Type", "Verification Status", "Product Family", "Inner Product Id", "Height (inches)", "Width (inches)", "Depth (inches)", "Weight (pounds)", "Case Quantity", "Pack Type", "Pack Size", "Material Number", "Vendors Product Name", "Short Description", "Long Description"],
        upcColumnIndex: 0,
        responseData: [],
        isLoadingCreate: false,

        // Computed state
        hasDropData: computed(state => {
            return state.dropData.length > 0;
        }),
        upcList: computed(state => {
            return (state.isDataValid > 0 && state.validCol[0] === state.dropData[0][0]) ? state.dropData.map(col => col[state.upcColumnIndex]) : [];
        }),
        isDataValid: computed(state => {

            if (state.hasDropData) {
                return JSON.stringify(state.dropData[0]) === JSON.stringify(state.validCol);
            }
            else return false;
        }),
        invalidDropDataAlert: computed(state => {
            return !state.isDataValid === state.hasDropData;
        }),
        displayData: computed(state => {
            if (state.isDataValid) {
                return state.dropData.slice(1).map((row) => {
                    var newRow = {};
                    newRow.id = row[0]; // TODO: need logic to disable view button if id doesn't exist
                    newRow.upca = row[0].replace(/ /g, ""); // removes all spaces
                    newRow.name = row[1];
                    newRow.brand = row[2] ? row[2] : "No Brand";
                    newRow.category = row[3] ? row[3] : "No Category";
                    newRow.description = row.length > 15 ? row[15] : "";
                    return newRow;
                });
            } else return [];
        }),

        requestData: computed(state => {
            if (state.isDataValid) {
                return state.dropData.slice(1).map((row) => {
                    var createProductRequestDTO = {};
                    createProductRequestDTO.LargestAvailableUPC = row[0].replace(/ /g, "");
                    createProductRequestDTO.Name = row[1];
                    createProductRequestDTO.ProductBrand = row[2];
                    createProductRequestDTO.ProductCategory = row[3];
                    createProductRequestDTO.InnerProductId = row[4];
                    createProductRequestDTO.HeightInches = row[5];
                    createProductRequestDTO.WidthInches = row[6];
                    createProductRequestDTO.DepthInches = row[7];
                    createProductRequestDTO.WeightPounds = row[8];
                    createProductRequestDTO.CaseQuantity = row[9];
                    createProductRequestDTO.PackType = row[10];
                    createProductRequestDTO.PackSize = row[11];
                    createProductRequestDTO.VendorProductName = row[12];
                    createProductRequestDTO.MaterialNumber = row[13];
                    createProductRequestDTO.IbcProduct = row[14];
                    createProductRequestDTO.ShortDescription = row[15];
                    createProductRequestDTO.FullDescription = row[16];

                    return createProductRequestDTO;
                });
            } else return [];
        }),

        // Thunks
        createMultipleProducts: thunk(async (actions, payload) => {
            const { requestData, alertToggle } = payload;
            actions.toggleLoadingCreate();
            ibcEnterpriseApi.createMultipleProducts(requestData)
                .then((result) => {
                    actions.toggleLoadingCreate();
                    if (result.data.length > 0) {
                        const response = result.data[0];
                        actions.setResponseData(response);
                        actions.responseToDropData(response);
                    }
                });
        }),

        // Actions
        responseToDropData: action((state, response) => {
            var data = [];
            if (response) {
                if (response.createdProducts.length > 0)
                    data = response.createdProducts.map(product => {
                        var row = [];
                        row.push(product.UPC10 ? product.UPC10 : product.UPCA);
                        row.push(product.ShortDescription);
                        row.push(product.ProductBrandId ? product.ProductBrandId : "No Brand");
                        row.push(product.ProductCategoryId ? product.ProductCategoryId : "No Category");
                        row.push(product.InnerProductId);
                        row.push(product.HeightInches);
                        row.push(product.WidthInches);
                        row.push(product.DepthInches);
                        row.push(product.WeightPounds);
                        row.push(product.CaseQuantity);
                        row.push(product.PackType);
                        row.push(product.PackSize);
                        row.push(product.MaterialNumber);
                        row.push(product.VendorProductName);
                        row.push(product.IbcProductId);
                        row.push(product.ShortDescription);
                        row.push(product.FullDescription);

                        return row;
                    });
                if (response.invalidProducts.length > 0)
                    data = data.concat(response.invalidProducts.map(product => {
                        var row = [];
                        row.push(product.UPC10 ? product.UPC10 : product.UPCA);
                        row.push(product.ShortDescription);
                        row.push(product.ProductBrandId ? product.ProductBrandId : "No Brand");
                        row.push(product.ProductCategoryId ? product.ProductCategoryId : "No Category");
                        row.push(product.InnerProductId);
                        row.push(product.HeightInches);
                        row.push(product.WidthInches);
                        row.push(product.DepthInches);
                        row.push(product.WeightPounds);
                        row.push(product.CaseQuantity);
                        row.push(product.PackType);
                        row.push(product.PackSize);
                        row.push(product.MaterialNumber);
                        row.push(product.VendorProductName);
                        row.push(product.IbcProductId);
                        row.push(product.ShortDescription);
                        row.push(product.FullDescription);

                        return row;
                    }));
            }
            state.dropData = data.unshift(state.dropData[0]);
        }),
        setResponseData: action((state, responseData) => {
            state.responseData = responseData;
        }),
        setDropData: action((state, dropData) => {
            state.dropData = dropData;
        }),
        appendDropData: action((state, dropData) => {
            state.dropData = (state.hasDropData) ? state.dropData.concat(dropData.slice(1)) : dropData;
        }),
        clearDropData: action(state => {
            state.dropData = [];
        }),
        toggleLoadingCreate: action(state => {
            state.isLoadingCreate = !state.isLoadingCreate;
        }),

    }
}