import React, { useState, useEffect } from 'react';
import { ibcEnterpriseApi } from '../../../apis/ibcEnterpriseApi';
import Grid from '@mui/material/Grid';
import { Row, Col } from "reactstrap";
import VirtualizedList from '../VirtualizedList';
import DividerWithText from '../DividerWithText';

const ProductSearchFilter = (props) => {

  const { setFilterOptions } = props;

  const [ibcProducts, setIbcProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [families, setFamilies] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    ibcEnterpriseApi.getAllIbcProducts().then(result => {
      const response = result.data;
      setIbcProducts(response);
    })
    ibcEnterpriseApi.getAllProductBrands().then(result => {
      const response = result.data;
      setBrands(response);
    })
    ibcEnterpriseApi.getAllProductCategories().then(result => {
      const response = result.data;
      setCategories(response);
    })
    ibcEnterpriseApi.getAllProductFamilies().then(result => {
      const response = result.data;
      setFamilies(response);
    })
    ibcEnterpriseApi.getAllProductSubCategories().then(result => {
      const response = result.data;
      setSubCategories(response);
    })
  }, []);

  const setIbcProductFilterId = (id) => {
    setFilterOptions(filterOptions => ({
      ...filterOptions,
      IbcProductId: id
    }))
  }

  const setBrandFilterId = (id) => {
    setFilterOptions(filterOptions => ({
      ...filterOptions,
      BrandId: id
    }))
  }

  const setCategoryFilterId = (id) => {
    setFilterOptions(filterOptions => ({
      ...filterOptions,
      CategoryId: id
    }))
  }

  const setFamilyFilterId = (id) => {
    setFilterOptions(filterOptions => ({
      ...filterOptions,
      FamilyId: id
    }))
  }

  const setSubCategoryFilterId = (id) => {
    setFilterOptions(filterOptions => ({
      ...filterOptions,
      SubcategoryId: id
    }))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={0 } sm={1}></Grid>
      <Grid item xs={12} sm={10}><DividerWithText>Filters</DividerWithText></Grid>
      <Grid item xs={0 } sm={1}></Grid>
      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={2}><VirtualizedList options={ibcProducts} name='I.B.C. Products' setFilterOption={setIbcProductFilterId} /></Grid>
      <Grid item xs={12} sm={2}><VirtualizedList options={brands} name='Brands' setFilterOption={setBrandFilterId} /></Grid>
      <Grid item xs={12} sm={2}><VirtualizedList options={categories} name='Categories' setFilterOption={setCategoryFilterId} /></Grid>
      <Grid item xs={12} sm={2}><VirtualizedList options={families} name='Families' setFilterOption={setFamilyFilterId} /></Grid>
      <Grid item xs={12} sm={2}><VirtualizedList options={subCategories} name='Subcategories' setFilterOption={setSubCategoryFilterId} /></Grid>
      <Grid item xs={12} sm={1}></Grid>
    </Grid>
  )
}

export default ProductSearchFilter;