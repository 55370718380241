import React from "react";
import { Redirect } from "react-router-dom";


import Dashboard from "../pages/Dashboard/dashboard";
import DashboardTemp from "../pages/template/index";


// Orders
import OrderCart from "../pages/Orders/OrderCart";
import OrderHistory from "../pages/Orders/OrderHistory";
// Products
import ProductLookup from "../pages/Products/ProductLookup";
import ProductSearch from "../pages/Products/ProductSearch";
import ProductCreate from "../pages/Products/ProductCreate";
import ProductLoad from "../pages/Products/ProductLoad";
import ProductBulkUpdate from "../pages/Products/ProductBulkUpdate";

import ProductDetail from "../pages/Products/ProductDetail";
import ProductEdit from "../pages/Products/ProductEdit";
import Login from "../pages/Login/login";
import ProductSettings from "../pages/Products/ProductSettings";




//////////////////////
// TEMPLATE IMPORTS //
//////////////////////

const authProtectedRoutes = [


	//////////////////////////
	// APP ROUTES ////////////
	//////////////////////////

	{ path: "/dashboard", component: DashboardTemp },
	{ path: "/index", component: DashboardTemp },

	// order routes
	{ path: "/orders/cart", component: OrderCart },
	{ path: "/orders/history", component: OrderHistory },

	// product routes
	{ path: "/products/search/:searchValue?/:pageNumber?/:pageSize?", component: ProductSearch },
	{ path: "/products/lookup", component: ProductLookup },
	{ path: "/products/create", component: ProductCreate },
	{ path: "/products/load", component: ProductLoad },
	{ path: "/products/detail/:id", component: ProductDetail },
	{ path: "/products/edit/:id", component: ProductEdit },
	{ path: "/products/bulkupdate/", component: ProductBulkUpdate },
	{ path: "/products/settings/", component: ProductSettings },


	
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/login", component: Login }
];

export { authProtectedRoutes, publicRoutes };
