import React from "react";
import { TextField, MenuItem } from '@material-ui/core';
import '../../../styles/ProductCreateForm.css';
import FieldError from "./FieldError";
import { Controller } from 'react-hook-form';


/* Props:
 *  className
 *  name
 *  defaultValue
 *  control
 *  rules
 *  items -> items must be an object with Id and Name properties
 *  error
 *  errorMessage
 * 
 * 
 * use case example:
 *  <ProductFormSelectField className="right" name="Brand" control={control} rules={{ required: false }} error={errors.Brand} errorMessage="Brand is required" items={brands} />
 * */
function ProductFormSelectField(props) {

  return (
    <div className={props.className}>
      <Controller as={TextField}
        name={props.name} label={props.name}
        defaultValue={props.defaultValue}
        control={props.control}
        select
        rules={props.rules}
        variant="outlined"
        size="small"
      >
        {props.items.map(item => (
          <MenuItem key={item.Id} value={item.Id}>
            {item.SalesforceId ? item.SalesforceId : item.Name}
          </MenuItem>
        ))}
      </Controller>
      {props.error && <FieldError errorMessage={props.errorMessage} />}
    </div>
  )
}
export default ProductFormSelectField;