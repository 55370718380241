import React, { useEffect } from 'react';

import { useParams } from "react-router-dom";
import '../../styles/ProductStyles.css';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import UnavailableProductImage from "../../assets/images/unavailable/product-image-unavailable.png";
import GenericAlert from "../../components/Common/GenericAlert";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { ibcEnterpriseApi } from '../../apis/ibcEnterpriseApi';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';



const ProductDetail = (props) => {

  // Getting state references w/ 'userStoreState'
  const {
    product,
    brand,
    category,
    ibcProduct,
    subcategory,
    productFamily,
    images,
    galleryImages,
    alert,
    brands,
    categories,
    subcategories,
    productFamilies,
    ibcProducts
  } = useStoreState(state => state);


  // Setting and Changing state w/ 'userStoreActions'
  const {
    fetchInitialState,
    toggleAlert,
    setGallery,
    resetProduct,
  } = useStoreActions(actions => actions);

  let { id } = useParams();

  // componentDidMount alternative
  useEffect(() => {

    const payload = {
      id: id,
      brands: brands,
      categories: categories,
      subcategories: subcategories,
      productFamilies: productFamilies,
      ibcProducts: ibcProducts
    }
    fetchInitialState(payload);
  }, []) // add ', []' to prevent calling it over and over 

  // Set galleryImages
  useEffect(() => {
    setGallery(null);
  }, [images]) // Whenever the images state changes 


  const handleEdit = () => {
    props.history.push({
      pathname: '/products/edit/' + product.Id,
    });
  }

  const handleDeleteProduct = () => {
    ibcEnterpriseApi.deleteProduct(
      props.match.params.id
    ).then(() => {
      resetProduct();
      props.history.push({
        pathname: '/products/search/'
      });
    });
  }

  return (
    <>
      <div className="page-content">
        {/* Delete Product Alert */}
        <GenericAlert
          {...props}
          alert={alert.deleteProduct}
          title="Are you sure?"
          description="Continue to permanently delete product"
          onConfirmTitle="Product successfully deleted"
          onConfirmDescription="Navigate to search products"
          onConfirmAction={handleDeleteProduct}
          onCancelTitle="Product deletion cancelled"
          onCancelDescription=""
          onCancelAction={() => { }}
        />
        <div className="container-fluid">
          <Breadcrumbs title={'Products'} breadcrumbItem={'Details'} />
          <Row>
            <Col xl="12">
              <Card>
                <Row>
                  <Col xl="4">
                    <div className="detail-image" style={{ paddingTop: "25px", paddingLeft: "25px" }}>
                      {   /* Conditional Rendering */
                        (galleryImages.length !== 0)
                          ?
                          <ImageGallery showPlayButton={false} items={galleryImages} />
                          :
                          <img src={UnavailableProductImage} alt='unavailable' />
                      }
                    </div>
                  </Col>
                  <Col xl="5">
                    <div className="detail-attribute-container" style={{ paddingTop: "25px", paddingBottom: "340px" }}>
                      {product ? <div>
                        <h3>{product.ShortDescription}</h3>
                        <div><b>Brand: </b> {brand.Name} </div>
                        <div><b>Category: </b> {category.Name} </div>
                        <div><b>Subcategory: </b> {subcategory.Name} </div>
                        <div><b>Product Family: </b> {productFamily.Name} </div>
                        <div><b>UPCA: </b> {product.UPCA} </div>
                        <div><b>IsColdVault: </b> {String(product.IsColdVault)} </div>
                        <div><b>IsAlcohol: </b> {String(product.IsAlcohol)} </div>
                        <div><b>Product Type: </b> {product.ProductType} </div>
                        <div><b>Case Quantity: </b> {product.CaseQuantity} </div>
                        <div><b>Pack Type: </b> {product.PackType} </div>
                        <div><b>Pack Size: </b> {product.PackSize} </div>
                        <div><b>Vendor's Product Name:</b> {product.VendorProductName} </div>
                        <div><b>Height (inches): </b> {product.HeightInches} </div>
                        <div><b>Width (inches): </b> {product.WidthInches} </div> {/*TODO: finish adding columns*/}
                        <div><b>Depth (inches): </b> {product.DepthInches} </div>
                        <div><b>Weight (pounds): </b> {product.WeightPounds} </div>
                        <div><b>Material Number: </b> {product.MaterialNumber} </div>
                        <div><b>IBC Product: </b> {ibcProduct ? ibcProduct.Name : null} </div>
                        <div><b>Description: </b> {product.FullDescription} </div>
                      </div> : <h1>No product to display</h1>}
                    </div>
                  </Col>
                  <Col xl="3">
                    <div className="detial-control-panel" style={{ paddingTop: "25px", paddingRight: "25px" }}>
                      <button className="btn btn-success" onClick={handleEdit}>Edit</button>
                      <button className="btn btn-danger" onClick={() => toggleAlert(alert.deleteProduct)}>Delete</button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
