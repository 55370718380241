import React from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory hook
import { Modal } from '@mui/material';
import { Box, Grid, Typography } from '@mui/material';
import ProductModalForm from '../Common/Product/ProductModalForm';
import styled from 'styled-components';

// Styles for the modal content
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

// Define a styled button
const EditPageButton = styled.button`
  color: white;
  background-color: #586ce4; 
  padding: 0 16px; /* Changed padding */
  text-transform: none;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  height: 20px; /* Set height */
  line-height: 20px; /* Center text vertically */
  
  &:hover {
    background-color: #304cdc; // Grey hover color
  }
`;

const ProductEditModal = (props) => {
  const { showModal = false, setShowModal } = props;
  const history = useHistory(); // Initialize useHistory hook

  const handleClose = () => setShowModal(false);

  const handleContinue = () => {
    if (props.editProduct) {
      history.push('/products/edit/' + props.editProduct.id);
    } else {
      window.alert('Product Not Found');
    }
  };

  return (
    <>
      <Modal open={showModal} onClose={handleClose}>
        <Box sx={style}>
          <Grid container spacing={4} p={2}>
            <Grid item xs={12} sm={6}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Product Quick Edit
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '16px' }}>
                <EditPageButton onClick={handleContinue}>Continue To Edit Product Page</EditPageButton>
              </Box>
            </Grid>
          </Grid>

          <ProductModalForm {...props} handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  )
}

export default ProductEditModal;
