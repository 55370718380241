import React from 'react';
import Dropzone from 'react-dropzone';
import '../../styles/ImageDropStyles.css'
import DragNDropIcon from '../../assets/images/icons/upload-image-icon.png'


function DZPreview(props) {
    const files = props.files.map(file => (
        <li key={file.name}>
            {file.name}
        </li>
    ));

    const renderAcceptedFiles = () => {
        if (files.length !== 0) {
            return (
                <div>
                    <h4>Accepted Files</h4>
                    <ul>{files}</ul>
                </div>
            )
        }
    }
    
    return (
    <Dropzone onDrop={props.onFileDrop} accept='image/jpeg, image/png'>
            {({ getRootProps, getInputProps }) => (
                <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <div className='dz-container'>
                            <div className='dz-title'>Drop images here or click to upload</div>
                            <img className='dz-image' src={DragNDropIcon} width='50px' height='50px' alt="DragNDrop" />
                        </div>
                    </div>
                    <aside>
                        {renderAcceptedFiles()}
                    </aside>
                </section>
            )}
        </Dropzone>
    );
}

export default DZPreview;